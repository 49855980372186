import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const Copyright = () => {
    return (
        <Typography variant="body2" color="#fff" align="center" marginTop={'4%'}>
            {'Copyright © '}
            <Link color="#fff" href="https://contractorwebzone.com/">
                ContractorWebZone
            </Link>{' '}
            {new Date().getFullYear()}
        </Typography>
    );
};

export default Copyright;