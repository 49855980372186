/**
=================================
ContractorWebZone Contractor Portal Application V2
NodeJS  |  React  |  MaterialUI  |  Material Dash 2
2022 / 2023
=================================
==========================================================
Main components such as charts, boxes, sidenav, dash found in /components directory

Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

Theme files for colors and base layout definitions found in /assets/theme
==========================================================
*/

const listItem = {
  defaultProps: {
    disableGutters: true,
  },

  styleOverrides: {
    root: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
};

export default listItem;
