import * as React from 'react';
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import MDButton from "components/MDButton";
import Typography from '@mui/material/Typography';
import GoogleIcon from '@mui/icons-material/Google';
import LinkIcon from '@mui/icons-material/InsertLink';
import { useEffect, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import api from "utils/axiosConfig";
import { apiUrl, chk } from "App";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import colors from 'assets/theme/base/colors';


export default function Google() {

    const [isLoading, setIsLoading] = useState(true);
    const [isManage, setIsManage] = useState(false);
    const [googleName, setName] = useState('');
    const [googleEmail, setEmail] = useState('');
    const [googlePic, setPicture] = useState('');
    const [googleUser, setAllValues] = useState({
        email: "",
        id: "",
        nomen: "",
        picture: "",
        verified_email: "",
    });

    const onSuccess = (res) => {
        console.log(res);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        api.post(apiUrl() + "api/v2/gmb/success/",
            {
                code: res.code,
                cs: cs,
                session: session
            }).then(response => {
                if (response.data.status == 'ok') {
                    setName(response.data.name);
                    setEmail(response.data.email);
                    setPicture(response.data.google_picture);
                    setIsManage(true);
                } else {
                    // Send Denied Snack
                }
            });


    };

    const onFailure = (res) => {
        console.log("Login failed: res:", res);
        alert(`Failed to login.`);
    };

    const googleSignIn = useGoogleLogin({
        onSuccess,
        onFailure,
        isSignedIn: true,
        accessType: "offline",
        scope: "https://www.googleapis.com/auth/business.manage",
        responseType: "code",
        prompt: "consent",
        flow: 'auth-code'
    });

    useEffect(() => {
        // axios.defaults.withCredentials = true;
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/gmb/check/", { cs: cs, session: session }).then(response => {
            if (response.data.status === 'ok') {
                // Set to Manage
                setIsManage(true);
                setName(response.data.name);
                setEmail(response.data.email);
                setPicture(response.data.google_picture);

            } else {
                // Set To Connect
                setIsManage(false)
            }

        }).then(response => {
            setIsLoading(false)
        });
    }, []);




    return (
        <Card sx={{ minWidth: 275, alignContent: "center"}}>
            <CardContent>
                <Grid container spacing={1} sx={{ display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "space-evenly", margin: "auto 0" }}>
                    <GoogleIcon sx={{margin: "auto 0"}} />
                    <Grid item >
                        <Typography variant="h5" component="div" sx={{ whiteSpace: "nowrap", color: colors.ywpYellow.main }}>
                            GoogleMyBusiness
                        </Typography>
                        <Typography variant="p" fontSize=".8rem">
                            {(isManage) ?
                                <Grid container spacing={3} sx={{ marginTop: "-10px" }}>
                                    <Grid item>
                                        <img style={{ borderRadius: "50%" }} alt={"Google Profile Image"} src={googlePic} height={40} width={40} />
                                    </Grid>
                                    <Grid item>
                                        <b>{googleName}</b><br />
                                        <b>{googleEmail}</b><br />
                                    </Grid>
                                </Grid>
                                :
                                <Box>
                                    Integrate Your GoogleMyBusiness With ContractorWebZone.
                                </Box>
                            }
                        </Typography>
                    </Grid>
                    <Grid item style={{ alignSelf: "flex-end", justifySelf: "flex-end" }}>
                        {(isLoading) ?
                            <CircularProgress color="info" size={60} />
                            : <>
                                {(isManage) ?
                                    <Box sx={{ textAlign: "center",  display: "flex", alignContent: "center" }}>
                                        <Box>
                                            <Box sx={{ textAlign: "center", marginBottom: "-30px" }}>
                                                <LinkIcon sx={{ fontSize: "50px", color: "#4CAF50" }} />
                                            </Box>
                                            <Box sx={{ textAlign: "center" }}>
                                                <MDTypography color={"success"} sx={{ fontWeight: "bold" }}>
                                                    Connected
                                                </MDTypography>
                                            </Box>
                                        </Box>


                                        <CardActions>
                                            <a href={"/GoogleMyBusiness"}><MDButton size="small" color="success">Manage</MDButton></a>
                                        </CardActions>
                                    </Box>
                                    :
                                    <CardActions>
                                        <MDButton variant="outlined" size="small" color="info" onClick={googleSignIn}>Connect</MDButton>
                                    </CardActions>
                                }
                            </>
                        }
                    </Grid>
                    <br /><br />
                </Grid>
            </CardContent>

        </Card>
    );
}
