/**
 =================================
 ContractorWebZone Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */

import {forwardRef} from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// custom styles for the NotificationItem
import menuItem from "components/Items/NotificationItem/styles";
import {NavLink} from "react-router-dom";

const badgeStyle = ({palette: {ywpBlue, ywpWhite}}) => ({
    background: () => {
        return ywpBlue.main
    },
    color: ywpWhite.main,
    width: "17px",
    height: "17px",
    fontSize:"12px",
    fontWeight: "bold",
    borderRadius:"50%",
    textAlign:"center",
    lineHeight:"18px",
    marginLeft:"7px",

});

const NotificationItem = forwardRef(({icon, title, badge, goto,  ...rest}, ref) => (
    <MenuItem {...rest} ref={ref} sx={(theme) => menuItem(theme)}>
        <MDBox component={Link} href={goto} py={0.5} display="flex" alignItems="center" lineHeight={1}>
            <MDTypography variant="body1" color="secondary" lineHeight={0.75}>
                {icon}
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" sx={{ml: 1}}>
                {title}
            </MDTypography>

            {(badge) ?
                <MDTypography variant="button" fontWeight="bold" sx={badgeStyle}>
                    {badge}
                </MDTypography> : ''
            }


        </MDBox>
    </MenuItem>
));

// Typechecking props for the NotificationItem
NotificationItem.propTypes = {
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
};

export default NotificationItem;
