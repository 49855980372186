import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "components/MDInput";
import MDButton from "components/MDButton";
import React, {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {apiUrl, chk} from "App";
import api from "utils/axiosConfig";
import CircularProgress from "@mui/material/CircularProgress";

function BillingInformation({billing}) {
    const {enqueueSnackbar} = useSnackbar();
    const [contractor, setContractor] = useState(billing.billing_info.contractor);
    const [addr1, setAddr1] = useState(billing.billing_info.address);
    const [addr2, setAddr2] = useState(billing.billing_info.address_2);
    const [email, setEmail] = useState(billing.billing_info.email);
    const [city, setCity] = useState(billing.billing_info.city);
    const [state, setState] = useState(billing.billing_info.state);
    const [zip, setZip] = useState(billing.billing_info.zip);
    const [updateLoading, setUpdateLoading] = useState(false);

    function updateInfo() {
        if (!addr1) {
            const message = "Your Address Cannot Be Empty";
            const variant = "error";
            enqueueSnackbar(message, {variant});
            return false;
        }
        if (!email) {
            const message = "Email Cannot Be Empty";
            const variant = "error";
            enqueueSnackbar(message, {variant});
            return false;
        }
        if (!city) {
            const message = "City Cannot Be Empty";
            const variant = "error";
            enqueueSnackbar(message, {variant});
            return false;
        }
        if (!state) {
            const message = "State Cannot Be Empty";
            const variant = "error";
            enqueueSnackbar(message, {variant});
            return false;
        }
        if (!zip) {
            const message = "Zip Cannot Be Empty";
            const variant = "error";
            enqueueSnackbar(message, {variant});
            return false;
        }
        if (email && ( !email.includes('@') || !email.includes('.') ) ) {
            const message = "Email appears to be invalid";
            const variant = "error";
            enqueueSnackbar(message, {variant});
            return false;
        }
        setUpdateLoading(true);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        api.post(apiUrl() + "api/v2/billing/update_profile/", {
            cs: cs,
            session: session,
            contractor: contractor,
            addr1: addr1,
            addr2: addr2,
            city: city,
            state: state,
            zip: zip,
            email: email,

        }).then(response => {
            console.log(response);
            if (response.data.status === "ok") {
                const message = "Your Profile has been saved.";
                const variant = "success";
                enqueueSnackbar(message, {variant});
                setUpdateLoading(false);
            }else{
                const message = "Error saving your info. Please try again";
                const variant = "error";
                enqueueSnackbar(message, {variant});
                setUpdateLoading(false);
            }

        });
    }

    return (
        <Card id="delete-account">
            <MDBox pt={3} px={2}>
                <MDTypography variant="h6" fontWeight="medium">
                    Billing Information
                </MDTypography>
            </MDBox>
            <MDBox pt={1} pb={2} px={2}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    <MDBox component="form" pb={2} px={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FormField
                                    style={{width: '100%'}}
                                    label="Contractor"
                                    defaultValue={contractor}
                                    disabled/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormField
                                    style={{width: '100%'}}
                                    label="Address 1"
                                    placeholder="123 EZ Street"
                                    defaultValue={addr1}
                                    onKeyUp={(e) => setAddr1(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormField
                                    style={{width: '100%'}}
                                    label="Address 2"
                                    placeholder="123 EZ Street"
                                    defaultValue={addr2}
                                    onKeyUp={(e) => setAddr2(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormField
                                    style={{width: '100%'}}
                                    label="Email"
                                    placeholder="example@email.com"
                                    inputProps={{type: "email"}}
                                    defaultValue={email}
                                    onKeyUp={(e) => setEmail(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormField
                                    style={{width: '100%'}}
                                    label="City"
                                    placeholder="Hollywood"
                                    defaultValue={city}
                                    onKeyUp={(e) => setCity(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormField
                                    style={{width: '100%'}}
                                    label="State"
                                    placeholder="CA"
                                    defaultValue={state}
                                    onKeyUp={(e) => setState(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormField
                                    style={{width: '100%'}}
                                    label="Zip"
                                    placeholder="90210"
                                    defaultValue={zip}
                                    onKeyUp={(e) => setZip(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {(updateLoading) ?
                                    <MDBox style={{width: "200px"}}>
                                        <CircularProgress color="info" size={30}/>
                                    </MDBox>
                                    :
                                    <MDButton variant="outlined" color="info" size="medium" onClick={updateInfo}>
                                        update info
                                    </MDButton>
                                }
                            </Grid>


                        </Grid>
                    </MDBox>
                </MDBox>
            </MDBox>
        </Card>
    );
}

export default BillingInformation;
