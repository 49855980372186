// @mui material components
import React, {useEffect} from "react";
import Grid from "@mui/material/Grid";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import PageLoading from "components/PageLoading"
import {apiUrl, chk} from "App";
import api from "utils/axiosConfig";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import MDInput from "components/MDInput";
import {Icon} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {useSnackbar} from "notistack";
import MDButton from "../../components/MDButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Dashboard() {

    const [loading, setLoading] = React.useState(true);
    const [loader, updateLoader] = React.useState(false);
    const [pages, setPages] = React.useState({});
    const [page, setPage] = React.useState({title:"No changes made", id: '0'});
    const [pageLoadId, setPageLoadId] = React.useState(0);
    const [publish, publishOpen] = React.useState(0);
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        // axios.defaults.withCredentials = true;
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/Website/PageTitle/getAll/", {cs: cs, session: session}).then(response => {
            if (response.data.status === 'ok') {
                setPages(response.data.pages);
            }
        }).then(response => {
            setLoading(false);
        });
    }, []);




    function setCurrentPageTitle(e, id){
        let tempObj = {};
        tempObj.title = e;
        tempObj.id = id;
        setPage(tempObj);
    }

    function savePageTitle(){
        if(page.id === '0') {
            const message = page.title;
            const variant = "error";
            enqueueSnackbar(message, {variant});
            return
        }
        setPageLoadId(page.id);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        api.post(apiUrl() + "api/v2/Website/PageTitle/save/", {
            cs: cs,
            session: session,
            title: page.title,
            page_id: page.id,

        }).then(response => {
            if(response.data.status !== 'ok'){
                const message = response.data.message;
                const variant = "error";
                enqueueSnackbar(message, {variant});
            }else{
                const message = 'Page Title saved successfully';
                const variant = "success";
                enqueueSnackbar(message, {variant});
            }
        }).then(response => {
            setPageLoadId(0)
        });
    }

    function handlePublishOpen(){
        publishOpen(true);
    }
    function handlePublishClose(){
        publishOpen(false);
    }

    function publishSite(){
        updateLoader(true);
            const ck = chk();
            const session = ck.session;
            const cs = ck.cs;

            api.post(apiUrl() + "api/v2/Website/save/", {cs: cs, session: session}).then(response => {
                if (response.data.status === 'ok') {
                    const message = "Your Header was successfully saved.";
                    const variant = "success";
                    enqueueSnackbar(message, {variant});
                }else if(response.data.status === 'error'){
                    const message = "There was a problem publishing your website." + response.data.message;
                    const variant = "error";
                    enqueueSnackbar(message, {variant});
                }else{
                    const message = "There was a problem publishing your website.";
                    const variant = "error";
                    enqueueSnackbar(message, {variant});
                }
            }).then(response => {
                publishOpen(false);
                updateLoader(false);
            });


    }
    if (loading) {
        return (
            <PageLoading/>
        )
    }
    return (
        <DashboardLayout>
            <DashboardNavbar/>

            <Box sx={{
                marginBottom: "10px",
                backgroundColor: "#3779AB",
                fontWeight: "bold",
                color: "#FFF",
                height: "30px"
            }}>

            </Box>
            <Paper sx={{padding: "30px"}}>
                <Box sx={{height:"50px", marginTop:"20px"}}>
                    <MDButton color="info" size="medium" sx={{float: "left"}} onClick={handlePublishOpen}>
                        Publish Changes To Website
                    </MDButton>
                </Box>
                {pages.map((page, key) =>
                    <Box key={key} sx={{borderBottom: "1px solid #DDD", marginBottom: "10px", padding: "5px"}}>
                        <Grid
                            container
                            spacing={3}
                            direction="row"
                        >
                            <Grid item xs={12} md={3}>
                                <Box>{page.name}</Box>
                            </Grid>
                            <Grid item xs={11} md={4}>
                                <MDInput
                                    fullWidth
                                    label="Title"
                                    defaultValue={page.title}
                                    onKeyUp={(e) => setCurrentPageTitle(e.target.value, page.id)}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Icon fontSize="large" style={{cursor: "pointer"}} color="success" onClick={savePageTitle}>save</Icon>
                                {(pageLoadId === page.id) ?
                                    <CircularProgress color="info" size={40}/>
                                    :
                                    <></>
                                }
                            </Grid>
                        </Grid>
                    </Box>
                )}

            </Paper>
            <Footer/>
            <Dialog
                open={publish}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"You are about to publish content LIVE to your website."}</DialogTitle>
                <DialogContent>
                    <Box p={3}>

                        This action can have unforeseen consequences should<br/>
                        the content in your website be currently under construction.<br/><br/>

                        You are agreeing to take all liability for the custom content on your website.
                        Please make sure the following:<br/><br/>
                        <ul>
                            <li>You are sure ContractorWebZone is not currently changing your website.</li>
                            <li>You do not have open support ticket(s) for website changes.</li>
                            <li>You trust your content and understand the risks.</li>
                            <li>Changes can be undone by reverting the custom content and saving</li>
                            <li>Depending on site size, this may take up to a couple minutes</li>
                        </ul>

                    </Box>
                </DialogContent>
                <DialogActions>


                    {(loader) ?
                        <CircularProgress color="info" size={40} sx={{
                            marginLeft: "30px"
                        }}/>
                        :
                        <>
                            <MDButton onClick={handlePublishClose} variant="outlined" color="info">Cancel</MDButton>
                            <MDButton id="deleteSelectedPinSubmit" onClick={publishSite} variant="outlined" color="error">Publish Website Live</MDButton>
                        </>
                    }
                </DialogActions>
            </Dialog>
        </DashboardLayout>
    );
}

export default Dashboard;
