/**
 =================================
 ContractorWebZone Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */

// @material-ui core components


// Material Dashboard 2 PRO React components

import colors from "assets/theme/base/colors"

const ywpBlue = colors.ywpBlue.main;
const ywpWhite = colors.ywpWhite.main;
import MDButton from "components/MDButton";
import Box from "@mui/material/Box";
import {Link} from 'react-router-dom';
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import React from "react";

function ReviewSave() {
    return (
        <Paper square elevation={0} sx={{p: 3}}>
            <Typography>Your pin has been successfully saved. </Typography>
            <Box p={2} mt={2} sx={{
                borderTop: "1px solid #DDD",
                height: "60px"
            }}>
                <a href={"/MapYourProjects/AddPin"}>
                    <MDButton variant="outlined" color="info" size="medium">
                        Add Another Pin
                    </MDButton>
                </a>
                <a href={"/MapYourProjects"}>
                    <MDButton variant="outlined" color="info" size="medium">
                        Go To Projects
                    </MDButton>
                </a>
            </Box>
        </Paper>
    );
}

export default ReviewSave;
