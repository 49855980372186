/**
 =================================
 ContractorWebZone Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */

import {useState} from "react";

// @mui material components
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Images
import burceMars from "assets/images/bruce-mars.jpg";

function Header({profile}) {
    const [visible, setVisible] = useState(true);

    const handleSetVisible = () => setVisible(!visible);

    return (
        <Card id="profile">
            <MDBox p={2}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item>
                        <Box
                            component="img"
                            sx={{
                                width: 350,
                                maxHeight: {xs: 233, md: 167},
                                maxWidth: {xs: 350, md: 250},
                            }}
                            alt="The house from the offer."
                            src={"https://" + profile.contractor.info.primary_domain + "/imageserver/UserMedia/" + profile.contractor.config.user_file + "/" + profile.contractor.config.logo}
                        />

                    </Grid>
                    <Grid item>
                        <MDBox height="100%" mt={0.5} lineHeight={1}>
                            <MDTypography variant="h5" fontWeight="medium">
                                {profile.contractor.info.contractor}
                            </MDTypography>
                            <MDTypography variant="button" color="text" fontWeight="medium">
                                <Link href={"https://" + profile.contractor.info.primary_domain} target="_blank" color="inherit">
                                    {profile.contractor.info.primary_domain}
                                </Link>
                            </MDTypography>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3} sx={{ml: "auto"}}>
                        <MDBox
                            display="flex"
                            justifyContent={{md: "flex-end"}}
                            alignItems="center"
                            lineHeight={1}
                        >
                            {/*<MDTypography variant="caption" fontWeight="regular">*/}
                            {/*     {visible ? "invisible" : "visible"}*/}
                            {/*</MDTypography>*/}
                            {/*<MDBox ml={1}>*/}
                            {/*    <Switch checked={visible} onChange={handleSetVisible} />*/}
                            {/*</MDBox>*/}
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
        </Card>
    );
}

export default Header;
