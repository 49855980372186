import React, {useState, useEffect} from 'react';
import Stepper from "react-stepper-horizontal";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import {apiUrl, chk} from "App";
import api from "utils/axiosConfig";
import {useSnackbar} from "notistack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";


function Fees({setActiveStep}) {
    const {enqueueSnackbar} = useSnackbar();
    const [isLoading, setIsLoading] = useState(true)
    const [fees, setFees] = useState(true)
    let total_amount = 0;
    useEffect(() => {

        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/completeSetup/fees/", {cs: cs, session: session}).then(response => {
            if (response.data.status === 'ok') {
                let feesList = [];
                response.data.fees.map(item => {
                    let temp = {order_num: item.order_num, amount: item.invoice_total}
                    feesList.push(temp);
                })
                setFees(feesList);
                setIsLoading(false);
            } else {
                const message = "Could not retrieve invoicing info  --  Please contact sales or support";
                const variant = "error";
                enqueueSnackbar(message, {variant});
                setIsLoading(false);
                setActiveStep(-2);
            }

        }).then(response => {

        });
    }, []);


    if (isLoading) {
        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
            >

                <Grid item xs={3}>
                    <CircularProgress color="info" size={120}/>
                </Grid>

            </Grid>
        );
    }

    return (
        <div style={{fontFamily: "Arial"}}>
            {/*<pre>*/}
            {/*     {JSON.stringify(fees, null, 2)}*/}
            {/*</pre>*/}
            {

                fees.map(function (item, i) {
                    console.log('test');
                    total_amount = +total_amount + +item.amount
                    return <div key={i}>
                        <a href={"https://nexus.ywpportal.com/generateInvoice.php?invoice=" + item.order_num}
                           target={"_blank"}
                           style={{color: "#FFF"}}
                        >
                            Invoice #{item.order_num}
                        </a>
                        |
                        ${item.amount}</div>
                })
            }
            <br/>
            <Typography>
                Your total due today is <b>${total_amount.toFixed(2)}</b>
            </Typography>
            <br/>
            Going forward, you'll be billed a monthly fee of <b>$107.45</b>. This comprehensive plan
            includes:<br/><br/>
            <Box sx={{fontSize: "12px", maxHeight:"300px", overflowY:"scroll"}}>
                <strong>BirdEye Reviews and MapYourProjects...our proven solution to elevate your online
                    presence.</strong><br/>
                <br/>
                <b>Included in Your Hosting:</b>
                • Unlimited Storage<br/>
                • Unlimited Bandwidth<br/>
                • Google Analytics<br/>
                • Spam Blocking<br/>
                • Live US-Based Support (Phone/Email)**<br/>
                • Five Email Boxes (a $24.95 value)*<br/>
                • WordPress Blogging<br/>
                <br/>
                <b>Your Contractor Account Features:</b><br/>
                <br/>
                • Make Online Payments / Payment Receipts<br/>
                • Website Form Submissions (Your Leads Inbox)<br/>
                • Website Metrics / Performance Scores<br/>
                • BirdEye Review & Reputation Management<br/>
                • MapYourProjects - Geo-Pinned Project Mapping<br/>
                • Advanced User Access to Website Settings & Custom Content<br/>
                <a href="https://account.contractorwebzone.com">Login to Your Contractor Account</a><br/>
                <br/>
                <strong>ContractorWebZone Hosting eliminates the need to pick and choose essential features like storage,
                    bandwidth, and ad-free content. </strong><br/>
                <br/>
                <strong>Our hosting is comprehensive, built on dedicated resources for the fastest load times and
                    priority bandwidth.</strong><br/>
                <br/>
                Valued at $169.85 . . . This combined promotional pricing at $99.95 saves you $69.90 / mo<br/>
                <hr/>
                <i>*Each email box includes 25 GB storage and includes unlimited aliases. Additional email addresses are
                    4.99 / mo each.</i><br/>
                <br/>
                <i>**Support Personnel are available Monday - Friday 8am to 5pm Central Time, via Phone or Email (866)
                    414-5775 ext. 300 / support@contractorwebzone.com</i><br/>
            </Box>

        </div>
    );
}

export default Fees;