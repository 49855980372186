import React, {useEffect, useState} from 'react';
import api from 'utils/axiosConfig';
import {apiUrl, chk} from "App.js";
import Box from "@mui/material/Box";
import Select from 'react-select';
import CircularProgress from "@mui/material/CircularProgress";
import {ComposableMap, Geographies, Geography, Marker, Annotation} from "react-simple-maps";
import {scaleQuantile} from "d3-scale";
import {geoCentroid} from "d3-geo";
import { Typography } from '@mui/material';
const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const allStates = [
    {"id": "AL", "val": "01"},
    {"id": "AK", "val": "02"},
    {"id": "AS", "val": "60"},
    {"id": "AZ", "val": "04"},
    {"id": "AR", "val": "05"},
    {"id": "CA", "val": "06"},
    {"id": "CO", "val": "08"},
    {"id": "CT", "val": "09"},
    {"id": "DE", "val": "10"},
    {"id": "DC", "val": "11"},
    {"id": "FL", "val": "12"},
    {"id": "FM", "val": "64"},
    {"id": "GA", "val": "13"},
    {"id": "GU", "val": "66"},
    {"id": "HI", "val": "15"},
    {"id": "ID", "val": "16"},
    {"id": "IL", "val": "17"},
    {"id": "IN", "val": "18"},
    {"id": "IA", "val": "19"},
    {"id": "KS", "val": "20"},
    {"id": "KY", "val": "21"},
    {"id": "LA", "val": "22"},
    {"id": "ME", "val": "23"},
    {"id": "MH", "val": "68"},
    {"id": "MD", "val": "24"},
    {"id": "MA", "val": "25"},
    {"id": "MI", "val": "26"},
    {"id": "MN", "val": "27"},
    {"id": "MS", "val": "28"},
    {"id": "MO", "val": "29"},
    {"id": "MT", "val": "30"},
    {"id": "NE", "val": "31"},
    {"id": "NV", "val": "32"},
    {"id": "NH", "val": "33"},
    {"id": "NJ", "val": "34"},
    {"id": "NM", "val": "35"},
    {"id": "NY", "val": "36"},
    {"id": "NC", "val": "37"},
    {"id": "ND", "val": "38"},
    {"id": "MP", "val": "69"},
    {"id": "OH", "val": "39"},
    {"id": "OK", "val": "40"},
    {"id": "OR", "val": "41"},
    {"id": "PW", "val": "70"},
    {"id": "PA", "val": "42"},
    {"id": "PR", "val": "72"},
    {"id": "RI", "val": "44"},
    {"id": "SC", "val": "45"},
    {"id": "SD", "val": "46"},
    {"id": "TN", "val": "47"},
    {"id": "TX", "val": "48"},
    {"id": "UM", "val": "74"},
    {"id": "UT", "val": "49"},
    {"id": "VT", "val": "50"},
    {"id": "VA", "val": "51"},
    {"id": "VI", "val": "78"},
    {"id": "WA", "val": "53"},
    {"id": "WV", "val": "54"},
    {"id": "WI", "val": "55"},
    {"id": "WY", "val": "56"}
];
const offsets = {
    VT: [50, -8],
    NH: [34, 2],
    MA: [30, -1],
    RI: [28, 2],
    CT: [35, 10],
    NJ: [34, 1],
    DE: [33, 0],
    MD: [47, 10],
    DC: [49, 21]
};
export default function Visits() {

    const durationSelect = [
        {value: '6daysAgo', label: '7 Days'},
        {value: '13daysAgo', label: '2 Weeks'},
        {value: '29daysAgo', label: '1 Month'},
        {value: '59daysAgo', label: '2 Months'},
        {value: '89daysAgo', label: '3 Months'},
        {value: '179daysAgo', label: '6 Months'},
        {value: '364daysAgo', label: '1 Year'},
    ];
    const [data, setData] = useState([]);

    const colorScale = scaleQuantile()
        .domain(data.map(d => d.val))
        .range([
            // "#e9f7ff",
            // "#C8E4FF",
            // "#9ED2FF",
            // "#70B4F2",
            // "#4B8DCB",
            // "#2D6AA1",

 
            "#E5E5E5", 
            "#BAD7F3", 
            "#70A9D8", 
            "#2E7ABA", 
            "#95C3EF", 
            "#15548E", 
            "#0A2A47"  

        ]);


    const [isLoading, setIsLoading] = useState(true);

    function handleDurationChange(e) {
        setIsLoading(true);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/analyticsV2/sessionByLocation/", {
            cs: cs,
            session: session,
            duration: e.value
        }).then(response => {
            setData(response.data.states)
        }).then(response => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        handleDurationChange({value: "26daysAgo"})
    }, []);

    return (
        <Box style={{backgroundColor: "white", borderRadius: "10px", padding: "5%", boxShadow: "1"}}>
                    <Typography sx={{ fontWeight: "bold" }}>
                        Top States By Visits
                    </Typography>
            <Select
                isSearchable="false"
                placeholder={"Duration"}
                defaultValue={"29daysAgo"}
                onChange={e => handleDurationChange(e)}
                options={durationSelect}
            />
            {(isLoading) ?
                <Box sx={{textAlign: "center"}}>
                    <CircularProgress color="info" size={100}/>
                </Box>
                :
                <div className="map-container">
                    <ComposableMap projection="geoAlbersUsa">
                        <Geographies geography={geoUrl}>
                            {({geographies}) => (
                                <>
                                    {geographies.map(geo => {
                                        console.log(geo.properties.name);
                                        const state = allStates.find(s => s.val === geo.id).id;
                                        const val = allStates.find(s => s.val === geo.id).val;
                                        const cur = data.find(s => s.id === state);
                                        return (
                                            <Geography
                                                key={geo.rsmKey}
                                                stroke="#FFF"
                                                geography={geo}
                                                style={{
                                                    default: {
                                                        fill: cur ? colorScale(cur.val) : "#EEE",
                                                        stroke: "#fff",
                                                        strokeWidth: 0.75,
                                                        outline: "none",
                                                    },
                                                    hover: {
                                                        fill: "#CFD8DC",
                                                        stroke: "#607D8B",
                                                        strokeWidth: 1,
                                                        outline: "none",
                                                    },
                                                    pressed: {
                                                        fill: "#28538a",
                                                        stroke: "#607D8B",
                                                        strokeWidth: 1,
                                                        outline: "none",
                                                    },
                                                }}

                                            />
                                        )
                                    })}
                                    {geographies.map(geo => {
                                        const centroid = geoCentroid(geo);
                                        const cur = allStates.find(s => s.val === geo.id);
                                        const state = allStates.find(s => s.val === geo.id).id;
                                        const val = (data.find(s => s.id === state)) ? data.find(s => s.id === state).val : '';
                                        return (
                                            <g key={geo.rsmKey + "-name"}>
                                                {cur &&
                                                    centroid[0] > -160 &&
                                                    centroid[0] < -67 &&
                                                    (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                                                        <Marker coordinates={centroid}>
                                                            <text y="2" fontSize={20} textAnchor="middle" fontWeight={"Bolder"} fill='white'>
                                                                {val}
                                                            </text>
                                                        </Marker>
                                                    ) : (
                                                        <Annotation
                                                            subject={centroid}
                                                            dx={offsets[cur.id][0]}
                                                            dy={offsets[cur.id][1]}
                                                        >
                                                            <text x={4} fontSize={10}
                                                                  alignmentBaseline="middle">
                                                                {val}
                                                            </text>
                                                        </Annotation>
                                                    ))}
                                            </g>
                                        );
                                    })}
                                </>
                            )}
                        </Geographies>
                    </ComposableMap>

                </div>
            }
        </Box>
    );
}
