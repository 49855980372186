/**
 =================================
 ContractorWebZone Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */
import {useState} from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Images
import google from "assets/images/small-logos/google.svg";
import facebook from "assets/images/small-logos/facebook.svg";




// Material Dashboard 2 PRO React components
import {useMaterialUIController} from "context";

function Accounts() {
    const [controller] = useMaterialUIController();
    const {darkMode} = controller;

    const [google2FA, setgoogle2FA] = useState(true);
    const [FaceBook2FA, setFaceBook2FA] = useState(true);
    const [atlassian2FA, setAtlassian2FA] = useState(true);
    const [asana2FA, setAsana2FA] = useState(false);

    const handleSetgoogle2FA = () => setgoogle2FA(!google2FA);
    const handleSetFaceBook2FA = () => setFaceBook2FA(!FaceBook2FA);
    const handleSetAtlassian2FA = () => setAtlassian2FA(!atlassian2FA);
    const handleSetAsana2FA = () => setAsana2FA(!asana2FA);

    return (
        <Card id="accounts">
            <MDBox p={3} lineHeight={1}>
                <MDBox mb={1}>
                    <MDTypography variant="h5">Accounts</MDTypography>
                </MDBox>
                <MDTypography variant="button" color="text">
                    Here you can setup and manage your integration settings.
                </MDTypography>
            </MDBox>
            <MDBox pt={2} pb={3} px={3}>
                <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems={{xs: "flex-start", sm: "center"}}
                    flexDirection={{xs: "column", sm: "row"}}
                >
                    <MDBox display="flex" alignItems="center">
                        <MDAvatar src={google} alt="Slack logo" variant="rounded"/>
                        <MDBox ml={2}>
                            <MDTypography variant="h5" fontWeight="medium">
                                Google
                            </MDTypography>

                        </MDBox>
                    </MDBox>
                    <MDBox
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        width={{xs: "100%", sm: "auto"}}
                        mt={{xs: 1, sm: 0}}
                    >
                        <MDBox lineHeight={0} mx={2}>
                            <MDTypography variant="button" color="text">
                                {google2FA ? "Enabled" : "Disabled"}
                            </MDTypography>
                        </MDBox>
                        <MDBox mr={1}>
                            <Switch checked={google2FA} onChange={handleSetgoogle2FA}/>
                        </MDBox>
                    </MDBox>
                </MDBox>
                <MDBox ml={2} pl={6} pt={2} lineHeight={1}>
                    <MDTypography variant="button" color="text">
                        You have authorized ContractorWebZone to view and Manage your Google Profile settings and Google My Business account. To revoke these privileges, disable Google access by ContractorWebZone using the toggle above.
                    </MDTypography>
                    <MDBox
                        bgColor={darkMode ? "grey-900" : "grey-100"}
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems={{xs: "flex-start", sm: "center"}}
                        flexDirection={{xs: "column", sm: "row"}}
                        my={3}
                        py={1}
                        pl={{xs: 1, sm: 2}}
                        pr={1}
                    >
                        <MDTypography variant="button" fontWeight="medium" color="text">
                            Google Access Token
                        </MDTypography>
                        <MDBox width={{xs: "100%", sm: "25%", md: "15%"}} mt={{xs: 1, sm: 0}}>
                            <Tooltip title="Access Token" placement="top">
                                <MDInput size="small" value="s987sadhjsdf90fhjsdaf9sdhsdf823j32h"/>
                            </Tooltip>
                        </MDBox>
                    </MDBox>
                    <MDBox
                        bgColor={darkMode ? "grey-900" : "grey-100"}
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems={{xs: "flex-start", sm: "center"}}
                        flexDirection={{xs: "column", sm: "row"}}
                        my={3}
                        py={1}
                        pl={{xs: 1, sm: 2}}
                        pr={1}
                    >
                        <MDTypography variant="button" fontWeight="medium" color="text">
                            Connected account
                        </MDTypography>
                        <MDBox
                            display="flex"
                            alignItems={{xs: "flex-start", sm: "center"}}
                            flexDirection={{xs: "column", sm: "row"}}
                        >
                            <MDBox mr={2} mb={{xs: 1, sm: 0}} lineHeight={0}>
                                <MDTypography variant="button" fontWeight="medium">
                                    someaccount@gmail.com
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </MDBox>
                <Divider/>
                <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems={{xs: "flex-start", sm: "center"}}
                    flexDirection={{xs: "column", sm: "row"}}
                >
                    <MDBox display="flex" alignItems="center">
                        <MDAvatar src={facebook} alt="Slack logo" variant="rounded"/>
                        <MDBox ml={2} lineHeight={0}>
                            <MDTypography variant="h5" fontWeight="medium">
                                Facebook
                            </MDTypography>
                            <MDTypography variant="button" color="text">
                                You haven't connected your Facebook account yet. Please enable, and log into FaceBook.
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                    <MDBox
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        width={{xs: "100%", sm: "auto"}}
                        mt={{xs: 1, sm: 0}}
                    >
                        <MDBox lineHeight={0} mx={2}>
                            <MDTypography variant="button" color="text">
                                {!FaceBook2FA ? "Enabled" : "Disabled"}
                            </MDTypography>
                        </MDBox>
                        <MDBox mr={1}>
                            <Switch
                                checked={true}
                                onChange={handleSetFaceBook2FA}

                            />
                        </MDBox>
                    </MDBox>
                </MDBox>
                <Divider/>

            </MDBox>
        </Card>
    );
}

export default Accounts;
