// @mui material components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { apiUrl, chk } from "App";
import api from "utils/axiosConfig";
import { useState } from "react";
import PageLoading from "components/PageLoading";
import React from "react";
import colors from "assets/theme/base/colors";

import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import Slide from "@mui/material/Slide";
import DatePicker from 'sassy-datepicker';
import { useSnackbar } from "notistack";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";


import MDInput from "components/MDInput";
import CircularProgress from "@mui/material/CircularProgress";
import MDTypography from "components/MDTypography";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function Dashboard() {
    const [isSeo, setIsSeo] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [report, setReport] = useState('');
    const [value, setValue] = React.useState(0);
    const [expenditure, setExpenditure] = React.useState(0);
    const [adWords, setAdWords] = React.useState(0);
    const [startDateOpen, setStartDateOpen] = React.useState();
    const [endDateOpen, setEndDateOpen] = React.useState(false);
    const [getDateLoader, setgetDateLoader] = React.useState(false);
    const [leads, setLeads] = React.useState(0);
    const [leadsClosed, setLeadsClosed] = React.useState(0);
    const [cpl, setCPL] = React.useState(Math.round(expenditure / leads));
    const [cpcl, setCPCL] = React.useState(Math.round(expenditure / leadsClosed));
    const [startDate, setStartDate] = React.useState('xxx');
    const [endDate, setEndDate] = React.useState('xxx');
    const { enqueueSnackbar } = useSnackbar();


    // ADVANCED SECTION
    const [advMarketingExp, setAdvMarketingExp] = React.useState(0);
    const [advMarketingLeads, setAdvMarketingLeads] = React.useState(0);
    const [advMarketingQual, setAdvMarketingQual] = React.useState(0);
    const [advMarketingClose, setAdvMarketingClose] = React.useState(0);
    const [advMarketingYWPExp, setAdvMarketingYWPExp] = React.useState(0);
    const [advMarketingYWPClose, setAdvMarketingYWPClose] = React.useState(0);
    const [advMarketingSales, setAdvMarketingSales] = React.useState(0);

    // Set By useEffect
    const [advCostPerLead, setAdvCostPerLead] = React.useState(0);
    const [advCostPerQualLead, setAdvCostPerQualLead] = React.useState(0);
    const [advCostPerClosedLead, setAdvCostPerClosedLead] = React.useState(0);
    const [advYwpCostPerClosedLead, setAdvYwpCostPerClosedLead] = React.useState(0);
    const [incomePerClosedLead, setIncomePerClosedLead] = React.useState(0);
    const [incomePerYWPLead, setIncomePerYwpLead] = React.useState(0);


    useEffect(() => {
        const advExp = advMarketingExp / advMarketingLeads;
        const advQual = advMarketingExp / advMarketingQual;
        const advClose = advMarketingExp / advMarketingClose;
        const advYwpCPL = advMarketingYWPExp / advMarketingYWPClose;
        const IPCL = advMarketingSales / advMarketingClose;
        const IPYL = (advMarketingYWPClose / advMarketingClose) * advMarketingSales;

        setAdvCostPerLead((typeof (advExp) === 'number'
            && advExp > 0
            && isFinite(advExp)) ? advExp.toFixed(2) : 0)
        setAdvCostPerQualLead((typeof (advQual) === 'number'
            && advQual > 0
            && isFinite(advQual)) ? advQual.toFixed(2) : 0)
        setAdvCostPerClosedLead((typeof (advClose) === 'number'
            && advClose > 0
            && isFinite(advClose)) ? advClose.toFixed(2) : 0)
        setAdvYwpCostPerClosedLead((typeof (advYwpCPL) === 'number'
            && advYwpCPL > 0
            && isFinite(advYwpCPL)) ? advYwpCPL.toFixed(2) : 0)
        setIncomePerClosedLead((typeof (IPCL) === 'number'
            && IPCL > 0
            && isFinite(IPCL)) ? IPCL.toFixed(2) : 0)
        setIncomePerYwpLead((typeof (IPYL) === 'number'
            && IPYL > 0
            && isFinite(IPYL)) ? IPYL.toFixed(2) : 0)

    }, [advMarketingExp,
        advMarketingLeads,
        advMarketingQual,
        advMarketingClose,
        advMarketingYWPExp,
        advMarketingYWPClose,
        advMarketingSales]);


    useEffect(() => {
        const CPL = (parseInt(expenditure) + parseInt(adWords)) / parseInt(leads);
        const CPCL = (parseInt(expenditure) + parseInt(adWords)) / parseInt(leadsClosed);
        setCPL((typeof (CPL) === 'number'
            && CPL > 0
            && isFinite(CPL)) ? Math.round(CPL * 100) / 100 : 0)
        setCPCL((typeof (CPCL) === 'number'
            && CPCL > 0
            && isFinite(CPCL)) ? Math.round(CPCL * 100) / 100 : 0)

    }, [expenditure,
        adWords,
        leads,
        leadsClosed]);


    const startDateChange = (date) => {
        setgetDateLoader(true);
        setStartDateOpen(false);
        let newDate = date.toString();

        newDate = newDate.replace("Mon ", "");
        newDate = newDate.replace("Tue ", "");
        newDate = newDate.replace("Wed ", "");
        newDate = newDate.replace("Thu ", "");
        newDate = newDate.replace("Fri ", "");
        newDate = newDate.replace("Sat ", "");
        newDate = newDate.replace("Sun ", "");
        newDate = newDate.split(' 00')[0]

        setStartDate(newDate);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        api.post(apiUrl() + "api/v2/seo/getLeads/", {
            cs: cs,
            session: session,
            startDate: newDate,
            endDate: endDate,

        }).then(response => {
            if (response.data.status === "ok") {
                setLeads(response.data.leads);
                setLeadsClosed(response.data.leads);
                // Commented out by Forrest. Error when compiling in my environment and does not appear to be used. 
                // handleCalculation(response.data.leads, response.data.leads, expenditure);
            }
            if (response.data.status == 'error') {
                const message = response.data.message;
                const variant = "error";
                enqueueSnackbar(message, { variant });
            }
            setgetDateLoader(false);
        });

    };
    const endDateChange = (date) => {
        let newDate = date.toString();
        setEndDateOpen(false);
        setgetDateLoader(true);
        newDate = newDate.replace("Mon ", "");
        newDate = newDate.replace("Tue ", "");
        newDate = newDate.replace("Wed ", "");
        newDate = newDate.replace("Thu ", "");
        newDate = newDate.replace("Fri ", "");
        newDate = newDate.replace("Sat ", "");
        newDate = newDate.replace("Sun ", "");
        newDate = newDate.split(' 00')[0]
        setEndDate(newDate);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        api.post(apiUrl() + "api/v2/seo/getLeads/", {
            cs: cs,
            session: session,
            startDate: startDate,
            endDate: newDate,
        }).then(response => {
            if (response.data.status === "ok") {
                setLeads(response.data.leads);
                setLeadsClosed(response.data.leads);
                // Commented out by Forrest. Errors were being thrown and this was unused. 
                // handleCalculation(response.data.leads, response.data.leads, expenditure);
            }
            if (response.data.status == 'error') {
                const message = response.data.message;
                const variant = "error";
                enqueueSnackbar(message, { variant });
            }
            setgetDateLoader(false);
        });


    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    useEffect(() => {
        setLoading(true);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        // axios.defaults.withCredentials = true;
        api.post(apiUrl() + "api/v2/seo/", { cs: cs, session: session }).then(response => {
            if (response.data.status === 'ok') {
                setIsSeo(response.data.seo);
                setReport(response.data.report);
                setExpenditure(response.data.expenditure);
                setAdWords(response.data.adwords_budget);
                setAdvMarketingYWPExp((parseInt(response.data.expenditure) + parseInt(response.data.adwords_budget)) * 100 / 100)
                setStartDate(response.data.start);
                setEndDate(response.data.end);
                setLeads(response.data.leads);
                setLeadsClosed(response.data.leads);
                setCPL(Math.round((response.data.expenditure + response.data.adwords_budget) / response.data.leads));
                setCPCL(Math.round((response.data.expenditure + response.data.adwords_budget) / response.data.leads));
            }
            setLoading(false);
        });
    }, []);

    if (isLoading) {
        return (
            <PageLoading />
        )
    }

    function handleOpenStartDate() {
        setStartDateOpen(true);
    }

    function handleOpenEndDate() {
        setEndDateOpen(true);
    }





    return (
        <DashboardLayout>
            <DashboardNavbar />
            {(isSeo) ?
                <Grid container direction="row" spacing={8} justifyContent="center">
                    <Grid item id="alert-dialog-slide-description" xs={12} md={5} marginTop="7.2%">
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <MDBox borderRadius="10px" padding="5%" >
                                    <Typography sx={{ fontSize: "2.5rem", fontWeight: "Bold", color: colors.ywpYellow.main, lineHeight:"1.2" }}>{"Measure Your"}</Typography>
                                    <Typography sx={{ fontSize: "2.5rem", fontWeight: "Bold", color: colors.ywpYellow.main, lineHeight:"1.2" }}>{"Marketing Success"}</Typography>

                                    <Typography sx={{ margin: "2% auto" }}>
                                        Measure your website marketing efforts to see your estimated return on investment.
                                        This tool helps you visualize what you're spending on your marketing efforts.
                                        Have a complicated marketing budget?
                                        Use our advanced calculator for a more accurate measurement.
                                    </Typography>

                                    <Typography sx={{ margin: "2% auto" }}>
                                        Contact ContractorWebZone's support team to learn more about increasing your web marketing efforts.
                                    </Typography>
                                        <br/>
                                    <MDButton variant="outlined" color="info" target="_top" rel="noopener noreferrer" href={`mailto:support@contractorwebzone.com`}>Email Support</MDButton>
                                </MDBox>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item id="alert-dialog-slide-description" xs={12} md={6}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{ minHeight: '100px', marginBottom: '3%' }} TabIndicatorProps={{maxHeight: "10px"}} >
                            <Tab label="BASIC CALCULATOR" {...a11yProps(0)} />
                            <Tab label="ADVANCED CALCULATOR" {...a11yProps(1)} />
                        </Tabs>
                        <TabPanel value={value} index={0}>
                            <Grid container spacing={3} sx={{ marginBottom: '3%' }}>
                                <Grid item lg={6}>
                                    <MDBox sx={{backgroundColor:colors.ywpYellow.main}} borderRadius="5px" padding="10%" minHeight="180px">
                                        <Box sx={{ textAlign: "center" }}>
                                            <Typography sx={{ fontSize: "36px;", color: colors.ywpWhite.main, fontWeight: "bold;" }}>
                                                ${(typeof (cpl) === 'number' && cpl > 0 && isFinite(cpl)) ? cpl : 0}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ textAlign: "center" }}>
                                            <Typography sx={{ color: "#fff;" }}>
                                                Your Cost Per Lead
                                            </Typography>
                                        </Box>
                                    </MDBox>
                                </Grid>
                                <Grid item lg={6}>
                                    <MDBox sx={{backgroundColor: colors.ywpYellow.focus}} borderRadius="5px" padding="10%" minHeight="180px">
                                        <Box sx={{ textAlign: "center" }}>
                                            <Typography sx={{ fontSize: "36px", color: colors.ywpWhite.main, fontWeight: "bold" }}>
                                                ${(typeof (cpcl) === 'number' && cpcl > 0 && isFinite(cpcl)) ? cpcl : 0}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ textAlign: "center" }}>
                                            <Typography sx={{ color: "#fff;" }}>
                                                Your Cost Per <b>Closed</b> Lead
                                            </Typography>
                                        </Box>
                                    </MDBox>
                                </Grid>
                            </Grid>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Grid container sx={{ minHeight: "400px;" }}>
                                        <MDTypography sx={{fontSize:"2rem", fontWeight:"Bolder", color:"#15548E", marginTop: "2%;"}}>Marketing Calculator</MDTypography>
                                        <Grid container spacing={3}>
                                            <Grid item lg={8}>
                                                <Typography sx={{ fontSize: "16px", lineHeight: "45px", }}>
                                                    How much do you spend with ContractorWebZone?
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={4}>
                                                <MDInput
                                                    defaultValue={expenditure}
                                                    fullWidth
                                                    label="YWP Expenditure"
                                                    inputProps={{ type: "text" }}
                                                    onKeyUp={(e) => setExpenditure(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item lg={8}>
                                                <Typography sx={{ fontSize: "16px", lineHeight: "45px", }}>
                                                    How much do you spend on AdWords?
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={4}>
                                                <MDInput
                                                    defaultValue={adWords}
                                                    fullWidth
                                                    label="AdWords Expenditure"
                                                    inputProps={{ type: "text" }}
                                                    onKeyUp={(e) => setAdWords(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item lg={8}>
                                                <Typography sx={{ fontSize: "16px", lineHeight: "45px", }}>
                                                    Inbox leads from <span style={{
                                                        textDecoration: "underline",
                                                        color: "#124676",
                                                        cursor: "pointer",
                                                        fontWeight: "bold"
                                                    }}
                                                        onClick={handleOpenStartDate}
                                                    >{startDate}</span> to <span
                                                        style={{
                                                            textDecoration: "underline",
                                                            color: "#124676",
                                                            cursor: "pointer",
                                                            fontWeight: "bold"
                                                        }}
                                                        onClick={handleOpenEndDate}
                                                    >{endDate}</span> {(getDateLoader) ? <CircularProgress color="info" size={15} /> : <></>}
                                                </Typography>
                                                {(startDateOpen) ?
                                                    <Box sx={{
                                                        position: "absolute",
                                                        backgroundColor: "#FFF",
                                                        border: "1px solid #124676",
                                                        top: "140px;"
                                                    }}>
                                                        <DatePicker onChange={startDateChange} />
                                                    </Box>
                                                    : <></>
                                                }
                                                {(endDateOpen) ?
                                                    <Box sx={{
                                                        position: "absolute",
                                                        backgroundColor: "#FFF",
                                                        border: "1px solid #124676",
                                                        top: "140px;"
                                                    }}>
                                                        <DatePicker onChange={endDateChange} />
                                                    </Box>
                                                    : <></>
                                                }
                                            </Grid>
                                            <Grid item lg={4}>
                                                <MDInput
                                                    defaultValue={leads}
                                                    key={leads}
                                                    fullWidth
                                                    label="Leads"
                                                    inputProps={{ type: "text" }}
                                                    onKeyUp={(e) => setLeads(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item lg={8}>
                                                <Typography sx={{ fontSize: "16px", lineHeight: "45px", }}>
                                                    How many of those leads did you close?
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={4}>
                                                <MDInput
                                                    defaultValue={leadsClosed}
                                                    key={leadsClosed}
                                                    fullWidth
                                                    label="Leads Closed"
                                                    inputProps={{ type: "text" }}
                                                    onKeyUp={(e) => setLeadsClosed(e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </TabPanel>



                        {/* Basic / Advanced Divider */}


                        <TabPanel value={value} index={1}>
                            <Grid container>
                                {/* Advanced Calculator Totals */}
                                <Grid container spacing={3} sx={{ marginBottom: '3%' }}>
                                    <Grid item lg={4}>
                                        <MDBox sx={{backgroundColor:colors.ywpYellow.main}}  borderRadius="5px" padding="10%" minHeight="150px">
                                            <Box sx={{ textAlign: "center" }}>
                                                <Typography sx={{ fontSize: "32px", color: colors.ywpWhite.main, fontWeight: "bold" }}>
                                                    ${advCostPerLead}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ textAlign: "center" }}>
                                                <Typography sx={{ fontSize: "18px", color: "#fff"}}>
                                                    Avg Cost <br />Per Lead
                                                </Typography>
                                            </Box>
                                        </MDBox>
                                    </Grid>
                                    <Grid item lg={4}>
                                        <MDBox sx={{backgroundColor:colors.ywpYellow.focus}} borderRadius="5px" padding="10%" minHeight="150px">
                                            <Box sx={{ textAlign: "center" }}>
                                                <Typography sx={{ fontSize: "32px", color: colors.ywpWhite.main, fontWeight: "bold" }}>
                                                    ${advCostPerQualLead}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ textAlign: "center" }}>
                                                <Typography sx={{ fontSize: "18px" , color: "#fff"}}>
                                                    Cost Per <br /><b>Qualified</b> Lead
                                                </Typography>
                                            </Box>
                                        </MDBox>
                                    </Grid>
                                    <Grid item lg={4}>
                                        <MDBox sx={{backgroundColor: colors.ywpYellow.main}} borderRadius="5px" padding="10%" minHeight="150px">
                                            <Box sx={{ textAlign: "center" }}>
                                                <Typography sx={{ fontSize: "32px", color: colors.ywpWhite.main, fontWeight: "bold" }}>
                                                    ${advCostPerClosedLead}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ textAlign: "center" }}>
                                                <Typography sx={{ fontSize: "18px" , color: "#fff"}}>
                                                    Cost Per <br /><b>Closed</b> Lead
                                                </Typography>
                                            </Box>
                                        </MDBox>
                                    </Grid>
                                </Grid>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>
                                    <MDTypography sx={{fontSize:"2rem" ,fontWeight:"Bolder" ,color:"#15548E", marginTop: "2%"}}>Advanced Calculator</MDTypography>
                                        <Grid container spacing={3} sx={{ marginTop: "1%;" }}>
                                            <Grid item lg={8}>
                                                <Typography sx={{ fontSize: "16px", lineHeight: "45px" , fontWeight: "bold"}}>
                                                    What is your total marketing budget?
                                                </Typography>
                                                <p style={{ fontSize: "12px" }}><i>Be sure to include what you pay for ContractorWebZone as well as any other marketing efforts you are currently using to generate leads</i></p>
                                            </Grid>
                                            <Grid item lg={4}>
                                                <MDInput
                                                    defaultValue={advMarketingExp}
                                                    fullWidth
                                                    label="Marketing Expenditure"
                                                    inputProps={{ type: "text" }}
                                                    onKeyUp={(e) => setAdvMarketingExp(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item lg={8}>
                                                <Typography sx={{ fontSize: "16px", lineHeight: "45px" , fontWeight: "bold"}}>
                                                    How many total leads?
                                                </Typography>
                                                <p style={{ fontSize: "12px" }}><i>Combine total leads from all marketing efforts</i></p>
                                            </Grid>
                                            <Grid item lg={4}>
                                                <MDInput
                                                    defaultValue={advMarketingLeads}
                                                    fullWidth
                                                    label="Total Leads"
                                                    inputProps={{ type: "text" }}
                                                    onKeyUp={(e) => setAdvMarketingLeads(e.target.value)}
                                                // onChange={handleCalculation}
                                                />
                                            </Grid>
                                            <Grid item lg={8}>
                                                <Typography sx={{ fontSize: "16px", lineHeight: "45px" , fontWeight: "bold"}}>
                                                    How many qualified leads?
                                                </Typography>
                                                <p style={{ fontSize: "12px" }}><i>Of your total amount of leads, how many were "qualified"</i></p>
                                            </Grid>
                                            <Grid item lg={4}>
                                                <MDInput
                                                    defaultValue={advMarketingQual}
                                                    fullWidth
                                                    label="Qualified Leads"
                                                    inputProps={{ type: "text" }}
                                                    onKeyUp={(e) => setAdvMarketingQual(e.target.value)}
                                                // onChange={handleCalculation}
                                                />
                                            </Grid>
                                            <Grid item lg={8}>
                                                <Typography sx={{ fontSize: "16px", lineHeight: "45px" , fontWeight: "bold"}}>
                                                    How many leads did you close?
                                                </Typography>
                                                <p style={{ fontSize: "12px" }}><i>Of your {advMarketingQual} qualified leads, how many led to a sale?</i></p>
                                            </Grid>
                                            <Grid item lg={4}>
                                                <MDInput
                                                    defaultValue={advMarketingClose}
                                                    fullWidth
                                                    label="Closes"
                                                    inputProps={{ type: "text" }}
                                                    onKeyUp={(e) => setAdvMarketingClose(e.target.value)}
                                                // onChange={handleCalculation}
                                                />
                                            </Grid>
                                            <Grid item lg={8}>
                                                <Typography sx={{ fontSize: "16px", lineHeight: "45px" , fontWeight: "bold"}}>
                                                ContractorWebZone Monthly Budget?
                                                </Typography>
                                                <p style={{ fontSize: "12px" }}><i>How much are you paying ContractorWebZone Monthly? (Including AdWords)</i></p>
                                            </Grid>
                                            <Grid item lg={4}>
                                                <MDInput
                                                    defaultValue={advMarketingYWPExp}
                                                    fullWidth
                                                    label="YWP Budget"
                                                    inputProps={{ type: "text" }}
                                                    onKeyUp={(e) => setAdvMarketingYWPExp(e.target.value)}
                                                // onChange={handleCalculation}
                                                />
                                            </Grid>
                                            <Grid item lg={8}>
                                                <Typography sx={{ fontSize: "16px", lineHeight: "45px" , fontWeight: "bold"}}>
                                                ContractorWebZone Closes
                                                </Typography>
                                                <p style={{ fontSize: "12px" }}><i>Of the {advMarketingClose} closed leads, how many were received from ContractorWebZone?</i></p>
                                            </Grid>
                                            <Grid item lg={4}>
                                                <MDInput
                                                    defaultValue={advMarketingYWPClose}
                                                    fullWidth
                                                    label="YWP Closes"
                                                    inputProps={{ type: "text" }}
                                                    onKeyUp={(e) => setAdvMarketingYWPClose(e.target.value)}
                                                // onChange={handleCalculation}
                                                />
                                            </Grid>
                                            <Grid item lg={8}>
                                                <Typography sx={{ fontSize: "16px", lineHeight: "45px" , fontWeight: "bold"}}>
                                                    Total Sales
                                                </Typography>
                                                <p style={{ fontSize: "12px" }}><i>Of the {advMarketingClose} closed sales, what was the total income of combined sales?</i></p>
                                            </Grid>
                                            <Grid item lg={4}>
                                                <MDInput
                                                    defaultValue={advMarketingSales}
                                                    fullWidth
                                                    label="Combined Sales"
                                                    inputProps={{ type: "text" }}
                                                    onKeyUp={(e) => setAdvMarketingSales(e.target.value)}
                                                // onChange={handleCalculation}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </TabPanel>
                    </Grid>
                </Grid >

                :

                <Box>
                    There was an error retrieving your Marketing information.<br />
                    You may not be signed up for our Marketing services. <br /><br />
                    If you have questions about marketing, please <a href={"mailto:info@contractorwebzone.com"}>Contact
                        Sales</a><br />
                    If you are a marketing customer and are getting this message, please <a
                        href={"mailto:support@contractorwebzone.com"}>Contact Support</a>
                </Box>
            }

        </DashboardLayout >
    );
}

export default Dashboard;