/**
 =================================
 ContractorWebZone Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */

import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {Breadcrumbs as MuiBreadcrumbs} from "@mui/material";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import routes from "routes";

import Cookies from 'universal-cookie';
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const cookies = new Cookies();

const contractor = cookies.get('contractor');

function Breadcrumbs({icon, route, light}) {

    let name = '';
    route.map(item => {
        name += (Object.values(routes).find(e => e.bcroute === item)).name + " / ";
    });

    const [isDeviceMobile, setIsMobile] = useState(false);
    const [BCname, setName] = useState(false);

    function handleWindowSizeChange() {

        if (window.innerWidth <= 768) {
            setIsMobile(true);
            setName(name);

        } else {
            setIsMobile(false);
            setName(contractor + ` / ` + name);
        }
    }

    useEffect(() => {
        handleWindowSizeChange();
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);


    if (isDeviceMobile) {
        return (
            <Box style={{
                width: "100vw",
                height: "60px",
                borderBottom: "1px solid #DDD",
                marginBottom:"-5px"
            }}>
                <Box style={{
                    width: "100vw",
                    height: "30px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: ".7em"
                }}>{contractor}</Box>
                <Box style={{
                    width: "100vw",
                    height: "30px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: ".7em"
                }}><Icon>{icon}</Icon> / {name}</Box>
            </Box>
        )
    } else {
        return (
            <MDBox mr={{xs: 0, xl: 8}}>
                <MuiBreadcrumbs
                    sx={{
                        "& .MuiBreadcrumbs-separator": {
                            color: ({palette: {white, grey}}) => (light ? white.main : grey[600]),
                        },
                    }}
                >

                    <Link to="/">
                        <MDTypography
                            component="span"
                            variant="body2"
                            color={light ? "white" : "dark"}
                            opacity={light ? 0.8 : 0.5}
                            sx={{lineHeight: 0}}
                        >
                            <Icon>{icon}</Icon>
                        </MDTypography>
                    </Link>
                    <MDTypography
                        variant="button"
                        fontWeight="bold"
                        textTransform="capitalize"
                        color={light ? "white" : "dark"}
                        sx={{lineHeight: 0}}
                    >
                        {BCname}
                    </MDTypography>
                </MuiBreadcrumbs>
            </MDBox>
        )
    }
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
    light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    light: PropTypes.bool,
};

export default Breadcrumbs;
