/**
 =================================
 ContractorWebZone Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */

// @material-ui core components


// Material Dashboard 2 PRO React components

import colors from "assets/theme/base/colors"

const ywpBlue = colors.ywpBlue.main;
const ywpWhite = colors.ywpWhite.main;

import MDButton from "components/MDButton";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ProjectInfo from "./ProjectInfo";
import AdvancedDetails from "./AdvancedDetails";
import FilterTags from "./FilterTags";
import Paper from "@mui/material/Paper";
import React from "react";
import InfoIcon from '@mui/icons-material/Info';
import SettingsIcon from '@mui/icons-material/Settings';
import TagsIcon from '@mui/icons-material/Loyalty';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}


function Details({
                     handleNext,
                     setProjectName,
                     projectName,
                     setClientName,
                     clientName,
                     setClientEmail,
                     clientEmail,
                     setClientPhone,
                     clientPhone,
                     description,
                     setDescription,
                     setDescriptionLen,
                     descriptionLen,
                     descriptionError,
                     setDescriptionError,
                     pageTitle,
                     setPageTitle,
                     metaDescription,
                     setMetaDescription,
                     filterTags,
                     setFilterTags,
                     isDeviceMobile,
                     projectNameError,
                     clientEmailError,
                     clientNameError,
                     projectDisabled,
                     invalidEmail,
                     setInvalidEmail,
                     fileList,
                     setClientEmailError,

                 }) {

    const [value, setValue] = React.useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    return (
        <Paper square elevation={0} sx={{p: (isDeviceMobile) ? 0 : 3}}>
            <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab icon={<InfoIcon/>} iconPosition="top" label="Info"/>
                <Tab icon={<SettingsIcon/>} label="Advanced"/>
                <Tab icon={<TagsIcon/>} label="Filters"/>
            </Tabs>
            <Box sx={{width: '100%'}}>

                <TabPanel value={value} index={0}>
                    <Box p={(isDeviceMobile) ? 0 : 2}>
                        <ProjectInfo
                            setProjectName={setProjectName}
                            projectName={projectName}
                            setClientName={setClientName}
                            setClientEmail={setClientEmail}
                            setClientPhone={setClientPhone}
                            clientName={clientName}
                            clientEmail={clientEmail}
                            clientPhone={clientPhone}
                            description={description}
                            setDescription={setDescription}
                            setDescriptionLen={setDescriptionLen}
                            descriptionLen={descriptionLen}
                            descriptionError={descriptionError}
                            setDescriptionError={setDescriptionError}
                            isDeviceMobile={isDeviceMobile}
                            projectNameError={projectNameError}
                            clientEmailError={clientEmailError}
                            clientNameError={clientNameError}
                            projectDisabled={projectDisabled}
                            invalidEmail={invalidEmail}
                            setInvalidEmail={setInvalidEmail}
                            setClientEmailError={setClientEmailError}
                        />
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Box p={2}>
                        <AdvancedDetails
                            pageTitle={pageTitle}
                            setPageTitle={setPageTitle}
                            metaDescription={metaDescription}
                            setMetaDescription={setMetaDescription}
                        />
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Box p={2}>
                        <FilterTags
                            filterTags={filterTags}
                            setFilterTags={setFilterTags}
                        />
                    </Box>
                </TabPanel>
            </Box>
            <Box p={2} mt={2} sx={{
                borderTop: "1px solid #DDD",
                height: "60px"
            }}>
                <MDButton variant="outlined" color="info" size="medium" sx={{float: "right"}} onClick={handleNext}>
                    NEXT
                </MDButton>
            </Box>

        </Paper>
    );
}

export default Details;
