// @mui material components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { apiUrl, chk } from "App";
import api from "utils/axiosConfig";
import { useState } from "react";
import PageLoading from "components/PageLoading";
import React from "react";

import Typography from "@mui/material/Typography";
import Report from "layouts/seo/components/report";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import DatePicker from 'sassy-datepicker';
import './styles/seo.css';
import { useSnackbar } from "notistack";

// FM Import
import IframeResizer from "iframe-resizer-react";


import MDInput from "components/MDInput";
import CircularProgress from "@mui/material/CircularProgress";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function Dashboard() {
    const [isSeo, setIsSeo] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [report, setReport] = useState('');
    const [value, setValue] = React.useState(0);
    const [expenditure, setExpenditure] = React.useState(0);
    const [adWords, setAdWords] = React.useState(0);
    const [startDateOpen, setStartDateOpen] = React.useState();
    const [endDateOpen, setEndDateOpen] = React.useState(false);
    const [getDateLoader, setgetDateLoader] = React.useState(false);
    const [leads, setLeads] = React.useState(0);
    const [leadsClosed, setLeadsClosed] = React.useState(0);
    const [cpl, setCPL] = React.useState(Math.round(expenditure / leads));
    const [cpcl, setCPCL] = React.useState(Math.round(expenditure / leadsClosed));
    const [startDate, setStartDate] = React.useState('xxx');
    const [endDate, setEndDate] = React.useState('xxx');
    const { enqueueSnackbar } = useSnackbar();


    // ADVANCED SECTION
    const [advMarketingExp, setAdvMarketingExp] = React.useState(0);
    const [advMarketingLeads, setAdvMarketingLeads] = React.useState(0);
    const [advMarketingQual, setAdvMarketingQual] = React.useState(0);
    const [advMarketingClose, setAdvMarketingClose] = React.useState(0);
    const [advMarketingYWPExp, setAdvMarketingYWPExp] = React.useState(0);
    const [advMarketingYWPClose, setAdvMarketingYWPClose] = React.useState(0);
    const [advMarketingSales, setAdvMarketingSales] = React.useState(0);

    // Set By useEffect
    const [advCostPerLead, setAdvCostPerLead] = React.useState(0);
    const [advCostPerQualLead, setAdvCostPerQualLead] = React.useState(0);
    const [advCostPerClosedLead, setAdvCostPerClosedLead] = React.useState(0);
    const [advYwpCostPerClosedLead, setAdvYwpCostPerClosedLead] = React.useState(0);
    const [incomePerClosedLead, setIncomePerClosedLead] = React.useState(0);
    const [incomePerYWPLead, setIncomePerYwpLead] = React.useState(0);


    useEffect(() => {
        const advExp = advMarketingExp / advMarketingLeads;
        const advQual = advMarketingExp / advMarketingQual;
        const advClose = advMarketingExp / advMarketingClose;
        const advYwpCPL = advMarketingYWPExp / advMarketingYWPClose;
        const IPCL = advMarketingSales / advMarketingClose;
        const IPYL = (advMarketingYWPClose / advMarketingClose) * advMarketingSales;

        setAdvCostPerLead((typeof (advExp) === 'number'
            && advExp > 0
            && isFinite(advExp)) ? advExp.toFixed(2) : 0)
        setAdvCostPerQualLead((typeof (advQual) === 'number'
            && advQual > 0
            && isFinite(advQual)) ? advQual.toFixed(2) : 0)
        setAdvCostPerClosedLead((typeof (advClose) === 'number'
            && advClose > 0
            && isFinite(advClose)) ? advClose.toFixed(2) : 0)
        setAdvYwpCostPerClosedLead((typeof (advYwpCPL) === 'number'
            && advYwpCPL > 0
            && isFinite(advYwpCPL)) ? advYwpCPL.toFixed(2) : 0)
        setIncomePerClosedLead((typeof (IPCL) === 'number'
            && IPCL > 0
            && isFinite(IPCL)) ? IPCL.toFixed(2) : 0)
        setIncomePerYwpLead((typeof (IPYL) === 'number'
            && IPYL > 0
            && isFinite(IPYL)) ? IPYL.toFixed(2) : 0)

    }, [advMarketingExp,
        advMarketingLeads,
        advMarketingQual,
        advMarketingClose,
        advMarketingYWPExp,
        advMarketingYWPClose,
        advMarketingSales]);


    useEffect(() => {
        const CPL = (parseInt(expenditure) + parseInt(adWords)) / parseInt(leads);
        const CPCL = (parseInt(expenditure) + parseInt(adWords)) / parseInt(leadsClosed);
        setCPL((typeof (CPL) === 'number'
            && CPL > 0
            && isFinite(CPL)) ? Math.round(CPL * 100) / 100 : 0)
        setCPCL((typeof (CPCL) === 'number'
            && CPCL > 0
            && isFinite(CPCL)) ? Math.round(CPCL * 100) / 100 : 0)

    }, [expenditure,
        adWords,
        leads,
        leadsClosed]);


    const startDateChange = (date) => {
        setgetDateLoader(true);
        setStartDateOpen(false);
        let newDate = date.toString();

        newDate = newDate.replace("Mon ", "");
        newDate = newDate.replace("Tue ", "");
        newDate = newDate.replace("Wed ", "");
        newDate = newDate.replace("Thu ", "");
        newDate = newDate.replace("Fri ", "");
        newDate = newDate.replace("Sat ", "");
        newDate = newDate.replace("Sun ", "");
        newDate = newDate.split(' 00')[0]

        setStartDate(newDate);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        api.post(apiUrl() + "api/v2/seo/getLeads/", {
            cs: cs,
            session: session,
            startDate: newDate,
            endDate: endDate,

        }).then(response => {
            if (response.data.status === "ok") {
                setLeads(response.data.leads);
                setLeadsClosed(response.data.leads);
                // Commented out by Forrest. Error when compiling in my environment and does not appear to be used. 
                // handleCalculation(response.data.leads, response.data.leads, expenditure);
            }
            if (response.data.status == 'error') {
                const message = response.data.message;
                const variant = "error";
                enqueueSnackbar(message, { variant });
            }
            setgetDateLoader(false);
        });

    };
    const endDateChange = (date) => {
        let newDate = date.toString();
        setEndDateOpen(false);
        setgetDateLoader(true);
        newDate = newDate.replace("Mon ", "");
        newDate = newDate.replace("Tue ", "");
        newDate = newDate.replace("Wed ", "");
        newDate = newDate.replace("Thu ", "");
        newDate = newDate.replace("Fri ", "");
        newDate = newDate.replace("Sat ", "");
        newDate = newDate.replace("Sun ", "");
        newDate = newDate.split(' 00')[0]
        setEndDate(newDate);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        api.post(apiUrl() + "api/v2/seo/getLeads/", {
            cs: cs,
            session: session,
            startDate: startDate,
            endDate: newDate,
        }).then(response => {
            if (response.data.status === "ok") {
                setLeads(response.data.leads);
                setLeadsClosed(response.data.leads);
                // Commented out by Forrest.
                // handleCalculation(response.data.leads, response.data.leads, expenditure);
            }
            if (response.data.status == 'error') {
                const message = response.data.message;
                const variant = "error";
                enqueueSnackbar(message, { variant });
            }
            setgetDateLoader(false);
        });


    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    useEffect(() => {
        setLoading(true);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        // axios.defaults.withCredentials = true;
        api.post(apiUrl() + "api/v2/seo/", { cs: cs, session: session }).then(response => {
            if (response.data.status === 'ok') {
                setIsSeo(response.data.seo);
                setReport(response.data.report);
                setExpenditure(response.data.expenditure);
                setAdWords(response.data.adwords_budget);
                setAdvMarketingYWPExp((parseInt(response.data.expenditure) + parseInt(response.data.adwords_budget)) * 100 / 100)
                setStartDate(response.data.start);
                setEndDate(response.data.end);
                setLeads(response.data.leads);
                setLeadsClosed(response.data.leads);
                setCPL(Math.round((response.data.expenditure + response.data.adwords_budget) / response.data.leads));
                setCPCL(Math.round((response.data.expenditure + response.data.adwords_budget) / response.data.leads));
            }
            setLoading(false);
        });
    }, []);

    if (isLoading) {
        return (
            <PageLoading />
        )
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="SEO ANALYTICS" {...a11yProps(0)} />
                {/* <Tab label="RANKINGS" {...a11yProps(1)} /> */}
                <Tab label="PAST REPORTS" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                {(isSeo) ?
                    <Box sx={{ height: "80vh" }}>
                        <Report report={report} />
                        <Footer />
                    </Box>
                    :
                    <Box>
                        There was an error retrieving your Marketing information.<br />
                        You may not be signed up for our Marketing services. <br /><br />
                        If you have questions about marketing, please <a href={"mailto:info@contractorwebzone.com"}>Contact
                            Sales</a><br />
                        If you are a marketing customer and are getting this message, please <a
                            href={"mailto:support@contractorwebzone.com"}>Contact Support</a>
                    </Box>
                }
            </TabPanel>
            {/* <TabPanel value={value} index={1}>
                <Box>EMPTY</Box>
            </TabPanel> */}
            <TabPanel value={value} index={1}>
                <Box sx={{ height: "80vh" }}>
                    <IframeResizer
                        src={report}
                        style={{ width: '100%', height: '100%' }}
                    />
                </Box>
            </TabPanel>
        </DashboardLayout>
    );
}

export default Dashboard;