import React, {useState, useEffect} from 'react';
import Stepper from "react-stepper-horizontal";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import {apiUrl, chk} from "App";
import api from "utils/axiosConfig";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Checkbox from '@mui/material/Checkbox';


function Signature({setActiveStep, setIsGood}) {
    const label = {inputProps: {'aria-label': 'Confirm TOS'}};

    const [contacts, setContacts] = useState(true)
    const [invalidEmail, setInvalidEmail] = useState(true)

    useEffect(() => {
        setIsGood(false)
    }, []);

    function handleChange(e) {
        setIsGood(false)
        if(e.value === true){
            setIsGood(true)
        }
    }

    return (
        <div style={{fontFamily: "Arial"}}>
            {/*<pre>*/}
            {/*     {JSON.stringify(contacts, null, 2)}*/}
            {/*</pre>*/}
            <Card style={{padding: 10}}>
                <Typography>
                    Please read our Terms and Service. A physical copy can be downloaded <a
                    href={"https://yourwebpro.com/support-docs/docs/13_YourWebPro-Terms-and-Conditions,-Terms-of-Service.pdf"}
                    target={"_blank"}>HERE </a> for your records.
                </Typography>
                <hr/>
                <div
                    style={{
                        backgroundColor: "#FFF",
                        width: "100%",
                        height: "250px",
                        marginTop: "20px",
                        overflowX: "scroll",
                        fontSize: "12px"
                    }}
                    id="outlined-helperText"
                >
                    ContractorWebZone.com Terms of Service and Conditions for ContractorWebZone Software and Services
                    <br/>
                    <br/>
                    The ContractorWebZone.com Terms of Service for ContractorWebZone Software and Services (“Agreement”) is
                    between ContractorWebZone.com ("ContractorWebZone") and you, your heirs, agents, successors and assigns (“You”
                    or “you” or “Your” or “your”) and is made effective at the time of use of ContractorWebZone. This Agreement
                    sets forth the terms and conditions of your use of ContractorWebZone software (“Software”) and subscription
                    service (“Services”) and explains ContractorWebZone's obligations to You and your obligations to ContractorWebZone
                    in relation to the Software and Services You use and/or purchase.
                    <br/>
                    <br/>
                    This Agreement as well as any additional ContractorWebZone policies, together with all modifications
                    thereto,
                    constitute the complete and exclusive agreement between You and ContractorWebZone concerning your use of
                    ContractorWebZone's Software and Services, and supersede and govern all prior proposals, agreements, or
                    other communications. By using or purchasing ContractorWebZone's Software or Services, You acknowledge
                    that you have read, understood, and agree to be bound by all terms and conditions of this Agreement
                    and any other policies or agreements made part of this Agreement by reference, as well as any new,
                    different or additional terms, conditions or policies which ContractorWebZone may establish from time to
                    time,
                    and any agreements that ContractorWebZone is currently bound by or will be bound by in the future.
                    <br/>
                    <br/>
                    1. Modifications: You agree that that ContractorWebZone may modify this Agreement and the Services from
                    time to time. You agree to be bound by any changes ContractorWebZone may reasonably make to this
                    Agreement. You are responsible for regularly reviewing the Terms of Use. Continued use of ContractorWebZone
                    services after any such changes constitutes your consent to such changes.
                    <br/>
                    <br/>
                    2. Accurate Information: You agree to maintain accurate information by providing updates to
                    ContractorWebZone, as needed.
                    <br/>
                    <br/>
                    3. No Unlawful Conduct or Improper Use: As a condition of your use of YourContractorWebZone's Software and
                    Services, you agree not to use them for any purpose that is unlawful or prohibited by these terms
                    and
                    conditions, and you agree to comply with any applicable local, state, federal and international
                    laws,
                    government rules or requirements. You agree not be entitled to a refund of any fees paid to
                    ContractorWebZone if, for any reason, ContractorWebZone takes corrective action with respect to your improper or
                    illegal use of its Services.
                    <br/>
                    <br/>
                    ContractorWebZone is under no obligation to view and or review your website or your activities to monitor
                    unlawful conduct or improper use. ContractorWebZone does not assume any responsibility to review clients'
                    websites for illegal, improper, or inaccurate content or activity.
                    <br/>
                    <br/>
                    4. Fees and Payments:
                    <br/>
                    <br/>
                    4.1 : Service Fee: As consideration for the subscription and authority to use Software or Services
                    purchased by you and provided to you by ContractorWebZone, You agree to pay ContractorWebZone at the time of
                    order. All fees are due immediately and are non-refundable unless otherwise expressly noted, even if
                    your Services are suspended, terminated, or transferred prior to the end of the Services term. You
                    agree
                    not to conduct any chargeback for Software or Services provided by ContractorWebZone.
                    <br/>
                    <br/>
                    In addition, Client shall reimburse ContractorWebZone, within thirty (30) days of being billed, for any
                    out-ofpocket costs incurred by ContractorWebZone in rendering the Services (the 'Miscellaneous Costs'),
                    including,
                    without limitation: (i) the costs of maintaining the domain name for the Client Website; the Client
                    will
                    automatically be charged (and be obligated to reimburse ContractorWebZone) for the costs of renewing and
                    maintaining the domain name registration and (ii) if applicable, the costs of providing e-commerce
                    features, customer support services and other solutions.
                    <br/>
                    <br/>
                    Terms and Conditions (continued):
                    <br/>
                    <br/>
                    4.2 Billing and Contact Information: You agree to provide ContractorWebZone with current and accurate
                    billing and contact information and shall promptly notify ContractorWebZone of any change in such
                    information. If the billing or contact information provided by you is incorrect or incomplete or
                    becomes
                    outdated, ContractorWebZone shall have the right to immediately suspend your services and access to your
                    software and services without any liability to you, until current and correct billing and contact
                    information is provided.
                    <br/>
                    <br/>
                    4.3 Timely Payment: You Agree that: (i) timely payment of the Service Fee for each billing period is
                    essential to the continuation of your software and services; (ii) Failure to pay the Service Fee on
                    time for
                    a given billing period shall entitle ContractorWebZone to immediately suspend your software and services
                    without any liability to you,; and (iii) if your account is delinquent for thirty (30) days or more,
                    then in
                    addition to its other rights and remedies, ContractorWebZone shall have the right to terminate this
                    Agreement
                    with immediate effect upon notice to you, upon which ContractorWebZone will, cease all of the Services,
                    deactivate and terminate your access, and suspend your account up to and including removal of any
                    and all material furnished by you. (iv) If, after (30) or more days of delinquent payments, should
                    you
                    choose to reactivate your subscription to your Service(s), ContractorWebZone, at its own discretion, will
                    charge
                    a $99.00 reactivation fee.
                    <br/>
                    <br/>
                    5. LIMITATION OF LIABILITY: THE SERVICES AND THE ASSOCIATED SOFTWARE (IF ANY) ARE BEING
                    PROVIDED AND/OR LICENSED “AS IS” AND CONTRACTORWEBZONE DISCLAIMS ANY AND ALL OTHER
                    WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION, ANY IMPLIED
                    WARRANTIES OF MERCHANTIBILITY OR FITNESS FOR A PARTICULAR PURPOSE, TO THE FULLEST EXTENT
                    PERMITTED OR AUTHORIZED BY LAW. WITHOUT LIMITATION OF THE FOREGOING, ContractorWebZone
                    EXPRESSLY DOES NOT WARRANT THAT THE SERVICES OR THE ASSOCIATED SOFTWARE WILL MEET YOUR
                    REQUIREMENTS OR THAT USE OF THE SERVICES OR OPERATION OF THE ASSOCIATED SOFTWARE WILL
                    BE UNINTERRUPTED OR ERROR FREE. IN NO EVENT SHALL ContractorWebZone BE LIABLE FOR ANY DIRECT,
                    INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT
                    LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR
                    BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER IN
                    CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY
                    OUT OF THE USE OF THE SERVICES OR SOFTWARE, INCLUDING AN INVALID TRANSFER, DENIAL OF A
                    TRANSFER, CHANGE OF ACCOUNT, OR OTHER SERVICES PROVIDED UNDER THIS AGREEMENT, EVEN
                    IF ContractorWebZone IS AWARE OF OR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
                    ContractorWebZone DISCLAIMS ANY AND ALL OTHER WARRANTIES, WHETHER EXPRESS OR IMPLIED,
                    INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTIBILITY OR FITNESS FOR A
                    PARTICULAR PURPOSE, TO THE FULLEST EXTENT PERMITTED OR AUTHORIZED BY LAW. WITHOUT
                    LIMITATION OF THE FOREGOING, ContractorWebZone EXPRESSLY DOES NOT WARRANT THAT THE SERVICES
                    OR THE ASSOCIATED SOFTWARE WILL MEET YOUR REQUIREMENTS OR THAT USE OF THE SERVICES OR
                    OPERATION OF THE ASSOCIATED SOFTWARE WILL BE UNINTERRUPTED OR ERROR FREE.
                    <br/>
                    <br/>
                    6. Indemnification: You agree to defend, indemnify and hold harmless ContractorWebZone and its contractors,
                    agents, employees, officers, directors, lawyers, shareholders, and affiliates from any loss,
                    liability,
                    damages or expense, including reasonable attorneys' fees, resulting from any third party claim,
                    action,
                    proceeding or demand related to your (including your agents affiliates, or anyone using your
                    account,
                    Software or Services with ContractorWebZone whether or not on your behalf, and whether or not with your
                    permission) use of the Software or Services You purchased from ContractorWebZone or your breach of this
                    Agreement or incorporated agreements and policies. In addition, You agree to indemnify and hold
                    ContractorWebZone harmless from any loss, liability, damages or expense, including reasonable attorneys'
                    fees,
                    <br/>
                    <br/>
                    Terms and Conditions (continued):
                    <br/>
                    <br/>
                    arising out of any breach of any representation or warranty provided herein, any negligence or
                    willful
                    misconduct by you, or any allegation that your account infringes a third person's copyright,
                    trademark
                    or proprietary or intellectual property right, or misappropriates a third person's trade secrets.
                    This
                    indemnification is in addition to any indemnification required of you elsewhere. Should ContractorWebZone
                    be
                    notified of a pending law suit or receive notice of the filing of a law suit, ContractorWebZone may seek a
                    written
                    confirmation from you concerning your obligation to indemnify ContractorWebZone. your failure to provide
                    such a confirmation may be considered a breach of this Agreement. You agree that ContractorWebZone shall
                    have the right to participate in the defense of any such claim through counsel of its own choosing.
                    You
                    agree to notify ContractorWebZone of any such claim promptly in writing and to allow ContractorWebZone to control
                    the proceedings. You agree to cooperate fully with ContractorWebZone during such proceedings.
                    You agree you will not be entitled to a refund of any fees paid to ContractorWebZone if, for any reason,
                    ContractorWebZone takes corrective action with respect to your improper or illegal use of its Services. You
                    also
                    agree that if ContractorWebZone is notified that a complaint has been filed with a governmental,
                    administrative
                    or judicial body, regarding an account of yours with ContractorWebZone, that ContractorWebZone, in its sole
                    discretion, may take whatever action ContractorWebZone deems necessary regarding further modification,
                    assignment of and/or control of your account to comply with the actions or requirements of the
                    governmental, administrative or judicial body until such time as the dispute is settled.
                    <br/>
                    <br/>
                    7. Governing Law, Venue; Waiver of Trial by Jury: This Agreement shall be deemed entered into in the
                    State of Illinois, County of Cook, USA. You agree that the laws and judicial decisions of Cook
                    County
                    Illinois, USA shall be used to determine the validity, construction, interpretation and legal effect
                    of this
                    Agreement. You agree that any action relating to or arising out of this Agreement shall be brought
                    in the
                    Courts of Cook County, Illinois USA. For the adjudication of disputes concerning the use of any
                    domain
                    name registered with ContractorWebZone, you agree to submit to jurisdiction and venue in the assigned
                    Court of Illinois, County of Cook, or the U.S. District Court for the Central District of Illinois,
                    located in
                    Chicago, Cook County, Illinois.
                    <br/>
                    <br/>
                    You agree to waive the right to trial by jury in any proceeding that takes place relating to or
                    arising out
                    of this Agreement.
                    <br/>
                    <br/>
                    8. Severability: You agree that the terms of this Agreement are severable. If any part of this
                    Agreement
                    is determined to be unenforceable or invalid, that part of the Agreement will be interpreted in
                    accordance with applicable law as closely as possible, in line with the original intention of both
                    parties to
                    the Agreement. The remaining terms and conditions of the Agreement will remain in full force and
                    effect.
                    <br/>
                    <br/>
                    9. Force Majeure: ContractorWebZone will make every effort to keep its website, your website, your
                    electronic
                    eMail account(s), Software and all other Services operational. However, certain technical
                    difficulties and
                    other factors outside of its control may, from time to time, result in service interruptions. You
                    agree not
                    to hold ContractorWebZone liable for any of the consequences of such interruptions.
                    <br/>
                    <br/>
                    10. Third Party Content: Third party content may appear on ContractorWebZone or may be accessible via
                    links.
                    ContractorWebZone is not responsible for and assumes no liability for any mistakes, misstatements of law,
                    defamation, slander, libel, omissions, falsehood, obscenity, pornography or profanity in the
                    statements,
                    opinions, representations or any other form of content contained in any third-party content
                    appearing
                    on ContractorWebZone.
                    <br/>
                    <br/>
                    Terms and Conditions (continued):
                    <br/>
                    <br/>
                    11. Advertisers: ContractorWebZone may contain advertising and sponsorship. Advertisers and sponsors are
                    responsible for ensuring that material submitted for inclusion on ContractorWebZone is accurate and
                    complies
                    with applicable laws. ContractorWebZone will not be responsible for the illegality of or any error or
                    inaccuracy
                    in advertisers' or sponsors' materials.
                    <br/>
                    <br/>
                    12. Registration: Certain sections of ContractorWebZone require you to register. If registration is
                    requested,
                    you agree to provide ContractorWebZone with accurate, complete registration information. It is your
                    responsibility to update your information and inform ContractorWebZone of any changes to that information.
                    <br/>
                    <br/>
                    13. Copyright / Proprietary Rights:
                    <br/>
                    <br/>
                    13.1 Copyright: ContractorWebZone's text, logos, designs, videos, images (print or digital) to market
                    ContractorWebZone Services and Software is proprietary.
                    <br/>
                    <br/>
                    13.2 Proprietary Rights: You agree for access to and use of ContractorWebZone's Services, and you are
                    not granted a license to any software by this Agreement. You acknowledge that you have no
                    proprietary
                    interest in the Services, including, but not limited to, the servers, software, or data used by us
                    in the
                    provision of the Services. All right, title and ownership to the Services, the Refined Data, or any
                    software
                    (both in object code and source code format), hardware or know-how which we license or develop to
                    provide the Services is owned exclusively by us or our licensors (if any). We retain all ownership
                    rights in
                    the Refined Data and the Services. You may only use the Refined Data in connection with your use of
                    the
                    Services. You agree not to copy, rent, lease, sell, distribute, or create derivative works based on
                    the
                    Services or the Refined Data in whole or in part, by any means, except as expressly authorized in
                    writing
                    by us. In addition, you agree not to, and to not cause, permit allow or facilitate others (directly
                    or
                    indirectly) to, modify, copy, or reverse engineer the Services or any part thereof. ContractorWebZone, the
                    ContractorWebZone logo, and other marks that we use from time to time are our trademarks. The appearance,
                    layout, color scheme, and design of the Site are protected trade dress. You may not use any of these
                    without our prior written permission. We encourage all customers to comment on the Services and
                    provide suggestions for improving it. You agree that all such comments and suggestions will be
                    nonconfidential and that we own all rights to use and incorporate them into the Services, without
                    payment
                    or attribution to you.
                    <br/>
                    <br/>
                    14. Service Access: Access to our proprietary Content Management System is limited to our personnel
                    only. No user interface is available and access by any third party is restricted. No Exceptions.
                    <br/>
                    <br/>
                    15. Termination of Services/Transfer of Content: Pursuant to the above statement, should you decide
                    you require “back-end” access, we will promptly convert your files and images to an electronic,
                    transportable, “zip” file. You can then direct your new developer / programmer to begin the process
                    of
                    rebuilding the website on his / her own hosting platform. Once your files are received, we will
                    discontinue servicing your account within 48 hours or as otherwise agreed.
                    * ContractorWebZone utilizes proprietary and advanced design and programming techniques. It is your
                    responsibility to
                    determine the capability of your new developer / programmer and his / her ability to replicate your
                    website to its
                    original form.
                    <br/>
                    <br/>
                    16. Hours of Operation: Friday 8:00 AM - 5:00 PM (Cent) at (847) 884-7400 ext. 300. Or after hours:
                    support@ContractorWebZone.com
                    <br/>
                    <br/>
                    Terms and Conditions (continued):
                    <br/>
                    <br/>
                    17. Modification/Addition of Content: During the early design process, narrative, images and other
                    elements are taken directly from the respective products' website and used universally by any
                    contractor utilizing those brands. Standard narrative and stock images associated with each section
                    have been set up with links as necessary to be informative to your customer. Any or all may be
                    replaced
                    with yours at your discretion and request.
                    Most modification requests are processed the same business day and no longer than 2 business days,
                    or
                    as otherwise agreed. Emergencies are processed as fast as conditions allow. Additions are processed
                    as
                    agreed, pursuant to the numbered statements below.
                    <br/>
                    <br/>
                    17.1 Modification: All product catalogs, sections and related pages are completely modifiable; plus,
                    any
                    section can be deactivated, or any new section not listed can be added.
                    Sections/pages added utilizing content, images and links provided by you are included with
                    monthly maintenance.
                    <br/>
                    <br/>
                    17.2 Addition: Sections/pages added utilizing content, images and links not provided by you are not
                    included with monthly maintenance but will be created and priced at time of request.
                    **Timetable for development of content, narrative, images, and links will be determined at time of
                    your
                    request. We will make every effort to process your request and develop the new section as quickly as
                    possible.
                    <br/>
                    <br/>
                    1. Creation and programing of entirely new brand name product lines will be set up as a
                    'staging' area for your review and approval prior to inclusion on your website.
                    <br/>
                    <br/>
                    2. Advanced programming, IE special forms, privacy areas or any other special request(s)
                    will be 'staged' for your review and approval.
                    <br/>
                    <br/>
                    3. Costs for the above services will be determined once approval of the staging area is
                    received, but there will be no obligation to accept estimate or completed work product.
                    <br/>
                    <br/>
                    18. Marketing/SEO:
                    <br/>
                    <br/>
                    18.1 Research/Staging & Expected Results: The ContractorWebZone SEO team conducts keyword research
                    based on your service area and utilizes proven SEO strategies to present a comprehensive online
                    marketing plan. After research is completed, you will be presented with your recommended
                    SEO/Marketing plan that has been designed to achieve the best possible rankings and potential lead
                    results. On average optimum results presented in your plan by your account manager vary between 3-6
                    months but can take up to 12 months to achieve desired results. You are responsible for choosing a
                    plan
                    that fits within your advertising budget.
                    <br/>
                    <br/>
                    **While Your Web Pro utilizes proven SEO and Marketing best practices to obtain the highest and best
                    possible
                    search engine ranking/listings, we do not guarantee any listing will result in a phone call or
                    contact nor do we
                    guarantee any phone call or contact will result in a sale. Your Web Pro recommends all clients
                    utilize a combination
                    of marketing techniques both on and off line to achieve the best lead/sales results.
                    <br/>
                    <br/>
                    18.2 Modification/Addition of Content:
                    <br/>
                    <br/>
                    Modification: All keywords up to max included in your current plan, custom programming,
                    related pages and social media are completely modifiable and included in your plan pricing.
                    Terms and Conditions (continued):
                    <br/>
                    <br/>
                    Addition: Keywords, custom programming, additional pages or social media content added
                    which exceed maximums and not included in your plan, will be researched and priced at time of
                    request.
                    <br/>
                    <br/>
                    18.3 Monthly Account Review: Your dedicated account manager will reach out during the 1st week of
                    each month to provide you a comprehensive review of the last months activity. Should you not be
                    available your account manager will try reaching you at least two times before emailing you a copy
                    of
                    your monthly report. Your account manager is available during regular hours of operation to answer
                    any questions you may have regarding your monthly report.
                    <br/>
                    <br/>
                    18.4 Hours of Operation: Friday 8:00 AM - 5:00 PM (Cent) at (847) 884-7400 ext. 105
                    <br/>
                    <br/>
                    18.5 Billing and Payments: A setup fee equivalent to first month's bill is assessed at the time of
                    startup.
                    Thereafter, monthly service will be billed on the 1st of each month. SEO Marketing/Advertising fees
                    such
                    as Google AdWords, Pay-per-Click and Social Media boosting that are prepaid monthly by ContractorWebZone
                    are nonrefundable. You are responsible for ensuring your payment and contact information is up to
                    date. Any delay of payment exceeding 20 days will be subject to a suspension of services as outlined
                    in
                    Terms of Service, Section 4, Fees & Payments.
                    <br/>
                    <br/>
                    18.6 Termination of Services: A 30-day notice is required before terminating SEO/Marketing services,
                    due to the hard costs associated with fees such as Google advertisements and/or Social Media
                    boosting.
                    <br/>
                    <br/>
                    19. Professional Email Services: Professional domain-based email services are offered with our
                    monthly,
                    quarterly or annual hosting to include: Five (5) eMail addresses and unlimited aliases. Additional
                    email
                    addresses are $3.99 per address. Upon termination of services, deleted email accounts are archived
                    temporarily and may be recoverable for a period of 7-14 days after account deletion.
                    <br/>
                    <br/>
                    ContractorWebZone <br/>
                    support@ContractorWebZone.com <br/>
                    (866) 414-5775 <br/>
                    Copyright © 2006 2024 ContractorWebZone.com
                </div>
                <br/>
                <hr/>
                I have read, and understand the Terms of Service <Checkbox
                {...label}
                onChange={(e) => {
                    handleChange({
                        value: e.target.checked
                    });
                }}/>
            </Card>
        </div>
    );
}

export default Signature;