/**
 =================================
 ContractorWebZone Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */


import colors from "assets/theme/base/colors"

const ywpBlue = colors.ywpBlue.main;
const ywpWhite = colors.ywpWhite.main;
import MDButton from "components/MDButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

function ReviewSave({
                        handleNext,
                        handleBack,
                        fileList,
                        allFileList,

                        projectName,
                        clientName,
                        clientEmail,
                        clientPhone,
                        description,

                        pageTitle,
                        metaDescription,

                        filterTags,

                        GAroute,
                        GAstreet,
                        GAcity,
                        GAstate,
                        GAzip,
                        GAcountry,
                        GALat,
                        GALon,
                        handleSavePin,
                        saveLoader,
                    }) {
    return (
        <Paper square elevation={0} sx={{p: 3, height: "525px"}}>
            <Typography>Review and Save</Typography>
            <Box p={2} mt={2} sx={{
                borderTop: "1px solid #DDD",
                height: "400px",
                overflowX: "scroll"
            }}>
                <b>Project:</b> {projectName}<br/>
                <b>Client:</b> {clientName}<br/>
                <b>Email:</b> {clientEmail}<br/>
                <b>Phone:</b> {clientPhone}<br/>
                <b>Description:</b> {description}<br/>
                <b>Address:</b> {GAstreet} {GAroute}, {GAcity} {GAstate}, {GAzip}<br/>
                {/*<b>Images:</b><br/>*/}
                {/*{(allFileList) ?*/}
                {/*    allFileList.map((file) => <div>{file.name}</div>)*/}
                {/*    :*/}
                {/*    <Box>None</Box>*/}
                {/*}*/}
                {/*<br/><br/>*/}


            </Box>
            {(saveLoader) ?
                <Box sx={{textAlign:"right"}}>
                    <CircularProgress color="info" size={40}/>
                </Box>
                :
                <Box>
                    <MDButton variant="outlined" color="info" size="medium" sx={{float: "left"}} onClick={handleBack}>
                        PREV
                    </MDButton>
                    <MDButton color="success" size="medium" sx={{float: "right"}} onClick={handleSavePin}>
                        Save Pin
                    </MDButton>
                </Box>
            }

        </Paper>
    );
}

export default ReviewSave;
