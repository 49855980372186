/**
 =================================
 ContractorWebZone Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */
import React, {useEffect, useState} from "react";
// @mui material components
import Card from "@mui/material/Card";
import Select, {SelectChangeEvent} from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Billing page components
import Invoice from "layouts/billing/components/Invoice";

function Invoices({allInvoices}) {

    const [age, setAge] = useState(0);



    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value);
    };

    return (
        <Card style={{
            height: "615px",
            overflowX: "hidden",
            overflowY: "scroll",
        }}
        >
            <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">

                <MDTypography variant="h6" fontWeight="medium">
                    Invoices
                </MDTypography>
                {/*<MDButton variant="outlined" color="info" size="medium">*/}
                {/*    view all*/}
                {/*</MDButton>*/}
            </MDBox>
            <MDBox pt={4} px={1} display="flex" justifyContent="space-between" alignItems="center">
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Date Range</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={age}
                        label="Date Range"
                        onChange={handleChange}
                        style={{height: 50}}
                    >
                        <MenuItem value={0} selected>Year To Date</MenuItem>
                        <MenuItem value={60}>Last 60 Days</MenuItem>
                        <MenuItem value={90}>Last 90 Days</MenuItem>
                        <MenuItem value={180}>last 6 Months</MenuItem>
                    </Select>
                </FormControl>
            </MDBox>
            <MDBox p={2}>
                <MDBox component="ul" display="flex" flexDirection="column" p={2} m={0}>
                    {allInvoices}
                </MDBox>
            </MDBox>
        </Card>
    );
}

export default Invoices;
