/**
 =================================
 ContractorWebZone Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function Invoice({date, id, price, paid, noGutter}) {
    return (
        <MDBox
            component="li"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            py={1}
            pr={1}
            p={1}
            mb={noGutter ? 0 : 1}
            borderBottom="1px solid #DDD"
            border={!paid ? "1px solid #FF0000" : ""}
        >
            <MDBox lineHeight={1.125}
            >
                <MDTypography
                    display="block"
                    variant="button"
                    fontWeight={!paid ? "bold" : "medium"}
                    color={!paid ? "error" : "info"}
                >
                    {date}
                </MDTypography>
                <MDTypography variant="caption" fontWeight="regular" color="text">
                    <Link
                        href={"https://nexus.ywpportal.com/generateInvoice.php?invoice=" + id}
                        underline="hover"
                        target="_blank"
                    >
                        {id}
                    </Link>
                </MDTypography>
            </MDBox>

            <MDBox display="flex" alignItems="center" p={2}>
                <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color={!paid ? "error" : "text"}
                >
                    {(!paid) ? "PAYMENT DUE" : "Paid"}
                </MDTypography>
            </MDBox>


            <MDBox display="flex" alignItems="center">
                <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color={!paid ? "error" : "text"}
                >
                    {price}
                </MDTypography>
                <MDBox display="flex" alignItems="center" lineHeight={1} ml={10} sx={{cursor: "pointer"}}>
                    <Link
                        href={"https://nexus.ywpportal.com/generateInvoice.php?invoice=" + id}
                        target="_blank">
                        <Icon fontSize="medium" color="info">picture_as_pdf</Icon>
                    </Link>
                </MDBox>
            </MDBox>
        </MDBox>
    );
}

// Setting default values for the props of Invoice
Invoice.defaultProps = {
    noGutter: false,
};

// Typechecking props for the Invoice
Invoice.propTypes = {
    date: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    noGutter: PropTypes.bool,
};

export default Invoice;
