/**
 =================================
 ContractorWebZone Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */

// import axios from 'axios';
import api from 'utils/axiosConfig';
import React, {useEffect, useState} from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

// Material Dashboard 2 React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import Header from "layouts/myp/components/Header";

import colors from "assets/theme/base/colors"

const ywpBlue = colors.ywpBlue.main;
const text = colors.text.main;
const ywpRed = colors.ywpRed.main;
const ywpWhite = colors.ywpWhite.main;
const ywpGreen = colors.ywpGreen.main;
import Checkbox from '@mui/material/Checkbox';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const loginToken = cookies.get('myCat');


//usage: {apiUrl()}
import {apiUrl, chk} from "App.js";
import PageLoading from "components/PageLoading";
import Box from "@mui/material/Box";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import NotificationItem from "components/Items/NotificationItem";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function MYP() {
    const [isDeviceMobile, setIsMobile] = useState(false);


    function handleWindowSizeChange() {
        if (window.innerWidth <= 768) {
            setIsMobile(true);
            return true;
        } else {
            setIsMobile(false);
            return false;
        }
    }

    useEffect(() => {
        const mobile = handleWindowSizeChange();
        if (mobile) {
            setDisplayType('list');
        }
        console.log("Is Mobile: " + mobile);

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);


    const [open, setOpen] = React.useState(false);
    const [allOpen, setAllOpen] = React.useState(false);
    const [displayType, setDisplayType] = React.useState('grid');
    const [project_id, setProjectId] = React.useState(0);
    const [mypChecked, setMypChecked] = React.useState([]);
    const [openMenu, setOpenMenu] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [searchTerm, setSearchTerm] = useState(null);
    const [searchMYP, setSearchMYP] = useState([]);


    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(false);


    const handleSetSearchTerm = (e) => {
        let search_term = e.toLowerCase();
        // setLoading(true)
        let tempMyp = [];
        myp.pins.map(item => {
            let name = item.name.toLowerCase();
            if (name.includes(search_term)) {
                tempMyp.push(item);
            }

        })
        setSearchMYP(tempMyp)
        buildProjectsGrid()
        buildProjectsList()
        console.log(tempMyp)
    }


    const handleMYPCheckChange = (e) => {
        const array = mypChecked; // make a separate copy of the array
        const isChecked = e.target.checked;
        const item = e.target.id;
        if (isChecked) {
            array.push(item);
            setMypChecked(array);
        } else {
            const index = array.indexOf(e.target.id)
            if (index !== -1) {
                array.splice(index, 1);
                setMypChecked(array);
            }
        }
        // console.log(mypChecked);
    }


    const handleMYPCheckAll = () => {
        // Check all and add them to mypChecked
        // setMypChecked(array);
        // const allPins = document.getElementsByClassName('myp_select');
        console.log(pins)
        let array = []; // make a separate copy of the array
        if (checkAll) {
            setCheckAll(false)
        } else {
            for (let i = 0; i < pins.length; i++) {
                array.push( 'pin_' + pins[i].project_id );
                // console.log('pin_' + pins[i].project_id)
            }
            setCheckAll(true)
        }
        setMypChecked(array)
        buildProjectsGrid();
    }

    function handleSetDisplayType(toggle, e) {
        setDisplayType(toggle)
    }


    const handleClickOpen = (e) => {
        const project_id = e.currentTarget.getAttribute("data-project_id");
        console.log('project_' + project_id);
        setOpen(true);
        setProjectId(project_id);
    };

    const handleClickOpenAll = () => {
        setAllOpen(true);
    };


    const handleClose = () => {
        setOpen(false);
        setAllOpen(false);
    };

    const handleDeletePin = (e) => {
        const project_id = e.currentTarget.getAttribute("data-project_id");
        //setLoading(true);
        // Make call to API to delete the item.
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        api.post(apiUrl() + "api/v2/myp/delete_pin/", {
            cs: cs,
            session: session,
            project_id: project_id
        }).then(response => {
            // alert(response.data);
            //setLoading(false);
            document.getElementById("project_" + project_id).remove()
            setOpen(false);
            setLoading(false);
        });

    };

    const handleDeleteSelectedPins = () => {
        if (mypChecked.length < 1) {
            alert('No pins selected for delete');
            setAllOpen(false);
            return false;
        }
        // Axios Call
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        api.post(apiUrl() + "api/v2/myp/delete_selected_pins/", {
            cs: cs,
            session: session,
            pins: mypChecked
        }).then(response => {
            // On Axios success: (response)
            mypChecked.map(d => {
                const pin_id = d.replace("pin_", "");
                document.getElementById("project_" + pin_id).remove();
                setAllOpen(false);
            })
            setMypChecked([]);
        });

        // console.log(mypChecked);


    };


    const [isLoading, setLoading] = useState(true);


    const [myp, setMyp] = useState();

    useEffect(() => {
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        // axios.defaults.withCredentials = true;
        api.post(apiUrl() + "api/v2/myp/", {cs: cs, session: session}).then(response => {
            if (response.data.status === 'ok') {
                setMyp(response.data);
                setLoading(false);
            } else if (response.data.error === 'not_enabled') {
                console.log('Maps Not Installed');
                response.data.pins = [];
                setMyp(response.data);
                setLoading(false);
            }
        });
    }, []);

    if (isLoading) {
        return (
            <PageLoading/>
        )
    }

    const pins = myp.pins;

    const noPins = (
        <Box sx={{minHeight: "400px"}}>
            MapYourProjects is not installed yet...
        </Box>
    );

    const handleEditPin = (e) => {
        const project_id = e.currentTarget.getAttribute("data-project_id");
        cookies.set('myp_e_p_id', project_id, {path: '/'});
        window.location.replace(
            "/MapYourProjects/EditPin"
        );
    };


    function buildProjectsGrid() {

        const mapPins = (searchMYP.length) ? searchMYP : pins;

        return (
            mapPins.map(item =>
                <Grid item sx={{
                    width: {xs: "100%", md: 250},
                    border: "1px solid #BBB",
                    marginBottom: 4,
                    padding: 2
                }}
                      key={item.project_id}
                      id={'project_' + item.project_id}>
                    <Box sx={{
                        width: {xs: "100%", md: 200},
                        backgroundColor: "#FFF",
                        fontSize: "30px",

                    }}>

                        {(checkAll) ?
                            <Checkbox
                                id={'pin_' + item.project_id}
                                className="myp_select"
                                defaultChecked={true}
                                onChange={handleMYPCheckChange}
                            />
                            :
                            <Checkbox
                                key={item.project_id}
                                id={'pin_' + item.project_id}
                                className="myp_select"
                                onChange={handleMYPCheckChange}
                            />
                        }

                        <a href={'https://' + myp.domain + '/project/' + item.uri} target="_blank">
                            <Icon
                                sx={{
                                    color: ywpGreen,
                                    cursor: "pointer",
                                    float: "right",
                                    marginTop: "10px",
                                    marginRight: "10px"
                                }}>visibility</Icon>
                        </a>
                    </Box>
                    <Box
                        component="div"
                        sx={{
                            width: {xs: "100%", md: 200},
                            minHeight: {xs: 200, md: 200},
                            background: (item.primary_image === 'none' ? "url('/images/placeholder.png ')" : "url('" + item.primary_image + "') center no-repeat"),
                            backgroundSize: "contain",
                            fontSize: "40px",
                            border: "2px solid #DDD"
                        }}
                    >

                    </Box>
                    <div>
                        <Typography variant="h5"
                                    gutterBottom
                                    sx={{
                                        color: ywpBlue,
                                        minHeight: 60,
                                    }}
                        >
                            {item.name}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h6" gutterBottom sx={{color: text}}>
                            {item.address}
                        </Typography>
                    </div>
                    <div>
                        <MDButton variant="outlined" style={{float: "left"}} color="info" size="small"
                                  onClick={handleEditPin}
                                  data-project_id={item.project_id}>
                            Edit
                        </MDButton>
                        <MDButton variant="outlined" style={{float: "right"}} color="error" size="small"
                                  onClick={handleClickOpen} data-project_id={item.project_id}>
                            Delete
                        </MDButton>
                    </div>
                </Grid>
            )
        )
    }


    function buildProjectsList() {
        const mapPins = (searchMYP.length) ? searchMYP : pins;
        return (
            mapPins.map(item =>
                <Grid item sx={{
                    width: "100%",
                    borderBottom: "1px solid #BBB",
                    marginBottom: 2,
                    padding: "0 !important"
                }}
                      key={item.project_id}
                      id={'project_' + item.project_id}>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        style={{minHeight: '50px'}}

                    >


                        <Grid item xs={1} lg={.5}>
                            <Checkbox id={'pin_' + item.project_id} className="myp_select"
                                      onChange={handleMYPCheckChange}/>
                        </Grid>
                        <Grid item xs={2}>
                            <Box
                                component="div"
                                sx={{
                                    width: 50,
                                    minHeight: 50,
                                    background: (item.primary_image === 'none' ? "url('/images/placeholder.png ')" : "url('" + item.primary_image + "') center no-repeat"),
                                    backgroundSize: "contain",
                                    fontSize: "40px",
                                    border: "2px solid #DDD",
                                    display: "inline-block",
                                }}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <Box sx={{
                                fontSize: ".8em",
                                height: "40px",
                                color: ywpBlue,
                                width: "95%",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}>
                                {item.name}
                            </Box>
                            <Box sx={{
                                marginTop: "-15px",
                                fontSize: ".7em",
                                height: "40px",
                                color: text,
                                width: "95%",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}>
                                {item.address}
                            </Box>
                        </Grid>
                        {/*<Grid item xs={1}>*/}
                        {/*    <Icon onClick={handleOpenMenu} fontSize={"large"} sx={{marginRight: "10px", color: ywpBlue, cursor: "pointer"}} title="Options">more_horiz</Icon>*/}

                        {/*</Grid>*/}
                    </Grid>
                    {/*<Menu*/}
                    {/*    anchorEl={openMenu}*/}
                    {/*    anchorReference={null}*/}
                    {/*    anchorOrigin={{*/}
                    {/*        vertical: "bottom",*/}
                    {/*        horizontal: "left",*/}
                    {/*    }}*/}
                    {/*    open={Boolean(openMenu)}*/}
                    {/*    onClose={handleCloseMenu}*/}
                    {/*    sx={{mt: 2}}*/}
                    {/*>*/}
                    {/*    {*/}
                    {/*        [*/}
                    {/*            <NotificationItem icon={<Icon sx={{color: "#1D71BF"}}  onClick={handleEditPin} data-project_id={item.project_id}>edit</Icon>} title="Edit Pin"/>,*/}
                    {/*            <NotificationItem icon={<Icon sx={{color: "#1D71BF"}}>visibility</Icon>} title="View Project"/>,*/}
                    {/*            <NotificationItem icon={<Icon sx={{color: "#FF0000"}}>delete_forever</Icon>} title="Delete"/>*/}
                    {/*        ]*/}
                    {/*    }*/}
                    {/*</Menu>*/}
                </Grid>
            )
        )
    }


    return (

        <DashboardLayout>
            <DashboardNavbar/>
            {(pins.length > 0) ?
                <Header
                    myp={myp}
                    handleMYPCheckAll={handleMYPCheckAll}
                    handleClickOpenAll={handleClickOpenAll}
                    handleSetDisplayType={handleSetDisplayType}
                    isDeviceMobile={isDeviceMobile}
                    handleSetSearchTerm={handleSetSearchTerm}
                    searchTerm={searchTerm}
                /> : <></>}
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={3}
                style={{marginLeft: "-10px", marginTop: "20px"}}
            >
                {(pins.length === 0) ? noPins :
                    (displayType === 'grid') ?
                        buildProjectsGrid()
                        :
                        buildProjectsList()
                }

            </Grid>


            <Footer/>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Are you sure you want to delete this pin?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        This will permanently remove the pin from MapYourProjects.
                        This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MDButton onClick={handleClose} variant="outlined" color="info">Cancel</MDButton>
                    <MDButton id="deletePinSubmit" onClick={handleDeletePin} variant="outlined" color="error"
                              data-project_id={project_id}>Delete Pin</MDButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={allOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Are you sure you want to delete ALL selected Pins??"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        This will permanently remove the selected pins from MapYourProjects.
                        This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MDButton onClick={handleClose} variant="outlined" color="info">Cancel</MDButton>
                    <MDButton id="deleteSelectedPinSubmit" onClick={handleDeleteSelectedPins} variant="outlined"
                              color="error" data-project_id={project_id}>Delete Pins</MDButton>
                </DialogActions>
            </Dialog>

        </DashboardLayout>
    );
}

export default MYP;
