// @mui material components
import React, {useState, useEffect} from "react";
import Grid from "@mui/material/Grid";
import FormField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Colors from 'assets/theme/base/colors'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Image} from 'mui-image';
// import BG from 'assets/images/desktop-bg.jpg';
// import BG from 'assets/images/bg19.jpg'
// import BG from 'assets/images/bg21.jpg'
// import BG from 'assets/images/bg32.jpg'
// import BG from 'assets/images/project6.jpg'
// import BG from 'assets/images/project14.jpg'
import BG from 'assets/images/unsplshbg1.jpg'
// import BG from 'assets/images/unsplshdel.jpg'




import Copyright from "layouts/Copyright";
import "./assets/css/login.css";

import Cookies from 'universal-cookie';
import api from "utils/axiosConfig";
import {apiUrl, chk} from "App.js";
import CircularProgress from "@mui/material/CircularProgress";
import {
    osName,
    osVersion,
    fullBrowserVersion,
    browserName,
    mobileVendor,
    mobileModel,
    deviceType,
    deviceDetect,
    OsTypes,
    BrowserTypes
} from 'react-device-detect';
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const {timeZone} = Intl.DateTimeFormat().resolvedOptions();

const OS = osName + " " + osVersion;
const browser = browserName + " " + fullBrowserVersion;
let mobile = 'NA';
if (mobileVendor !== 'none' && mobileModel !== 'none') {
    mobile = mobileVendor + " " + mobileModel;
}
const formFactor = deviceType;

const cookies = new Cookies();

const theme = createTheme({
    palette: {
        primary: {
            main: "#124676",
        },
        secondary: {
            main: "#f10011",
        },
    }
});


function Login() {

    const [displayError, toggleError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [rememberMe, setRememberMe] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [forgotPassOpen, setForgotPassOpen] = useState(false);
    const [forgotUserOpen, setForgotUserOpen] = useState(false);
    const [forgotThinking, setForgotThinking] = useState(false);
    const [forgotUThinking, setForgotUThinking] = useState(false);
    const [forgotUsername, setForgotUsername] = useState('');
    const [forgotError, setForgotError] = useState('');
    const [forgotUError, setForgotUError] = useState('');
    const [forgotPhone, setForgotPhone] = useState('');
    const [forgotEmail, setForgotEmail] = useState('');
    const [forgotMethod, setForgotMethod] = useState('');
    const [code, setCode] = useState('');
    const [timerDisplay, setTimerDisplay] = useState('');
    const [oneTimeToken, setOneTimeToken] = useState('');
    const [pass, setPass] = useState('');
    const [rPass, setRPass] = useState('');

    const [forgotBody, setForgotBody] = useState('forgot');
    const [userBody, setUserBody] = useState('forgot');

    function handleForgotPassOpen() {
        setForgotPassOpen(true)
    }

    function handleForgotUserOpen() {
        setForgotUserOpen(true)
    }

    function handleForgotPassClose() {
        setForgotPassOpen(false)
    }

    function handleForgotUserClose() {
        setForgotUserOpen(false)
    }

    function handleSetForgotMethod(e) {
        setForgotMethod(e)
    }

    let timer;

    function ValidateEmail(mail)
    {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
        {
            return true
        }
        return false
    }

    function startTimer(duration) {
        timer = duration;
        let minutes, seconds;
        setInterval(function () {
            minutes = parseInt(timer / 60, 10)
            seconds = parseInt(timer % 60, 10);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            setTimerDisplay(minutes + ":" + seconds);

            if (--timer < 0) {
                // window.location.reload(false);
            }
        }, 1000);
    }

    function handleForgotUserName(){
        setForgotUError('')
        if (!ValidateEmail(forgotEmail)){
            setForgotUError('Email address is invalid.');
            return false
        }
        setForgotUThinking(true)
        api.post(apiUrl() + "api/v2/login/forgot/username/", {email: forgotEmail}).then(response => {
            // On Axios success: (response)
            if (response.data.status === 'ok') {
                setUserBody('done');
                setTimeout(() => window.location.reload(), 2000);
            } else {
                setForgotUError("That username doesn't match our records.");
            }
            setForgotUThinking(false)

        });

    }


    function handleForgotPreflight() {
        setForgotThinking(true);
        setForgotError('');
        api.post(apiUrl() + "api/v2/login/forgot/password/verify/get/", {username: forgotUsername}).then(response => {
            // On Axios success: (response)
            if (response.data.status === 'ok') {
                setForgotBody('verify');
                if (response.data.phone) {
                    setForgotPhone(response.data.phone)
                }
                if (response.data.email) {
                    setForgotEmail(response.data.email)
                }
            } else if (response.data.status === 'no_contact') {
                setForgotBody('no_contact');
            } else {
                setForgotError("That username doesn't match our records.");
            }
            setForgotThinking(false)

        });
    }

    function handleForgotVerify() {
        setForgotError('');
        if (!forgotMethod) {
            setForgotError('You must choose a method to send the code');
            return false;
        }
        setForgotThinking(true);
        // Send the code
        api.post(apiUrl() + "api/v2/login/forgot/password/verify/send/", {username: forgotUsername, method: forgotMethod}).then(response => {
            // On Axios success: (response)
            if (response.data.status === 'ok') {
                startTimer(600);
                setForgotBody('enter_code');
            } else if (response.data.status === 'no_contact') {
                setForgotBody('no_contact');
            } else {
                setForgotError("There was an error processing that request.");
            }
            setForgotThinking(false)
        });
    }

    function handleVerifyCode() {
        setForgotError('');
        if (!forgotMethod) {
            setForgotError('You must choose a method to send the code');
            return false;
        }
        setForgotThinking(true);
        // Send the code
        api.post(apiUrl() + "api/v2/login/forgot/password/verify/", {username: forgotUsername, code: code}).then(response => {
            // On Axios success: (response)
            if (response.data.status === 'ok') {
                setForgotBody('reset_password');
                setOneTimeToken(response.data.one_time_token);
            } else if (response.data.status === 'expired') {
                setForgotBody('expired');
                setTimeout(() => window.location.reload(), 2000);
            } else {
                setForgotError("Your One Time Passcode does not match");
            }
            setForgotThinking(false)
        });
    }

    function handleReset() {
        setForgotError('');
        if (pass !== rPass) {
            setForgotError('Passwords do no match');
            return false;
        }
        if (!/[A-Z]/.test(pass)) {
            setForgotError('Password must contain an uppercase character');
            return false;
        }
        if (!/[a-z]/.test(pass)) {
            setForgotError('Password must contain a lowercase character');
            return false;
        }
        if (!/[0-9]/.test(pass)) {
            setForgotError('Password must contain a number');
            return false;
        }
        if (/^[a-zA-Z0-9]+$/.test(pass)) {
            setForgotError('Password must contain a special character');
            return false;
        }
        if (pass.length < 8) {
            setForgotError('Password must be at least 8 characters');
            return false;
        }
        setForgotThinking(true);
        api.post(apiUrl() + "api/v2/login/forgot/password/", {username: forgotUsername, code: code, oneTimeToken: oneTimeToken, pass: pass}).then(response => {
            // On Axios success: (response)
            if (response.data.status === 'ok') {
                window.location.reload();
            } else if (response.data.status === 'expired') {
                setForgotBody('expired');
                setTimeout(() => window.location.reload(), 2000);
            } else {
                setForgotBody('');
                setForgotError("There was an error processing your request.  Please try again.");
                setTimeout(() => window.location.reload(), 2000);
            }
            setForgotThinking(false)
        });

    }

    function containsSpecialChars(str) {
        const specialChars = /[`!@#$%^&*()+=\[\]{};':"\\|,.<>\/?~]/;
        return specialChars.test(str);
    }

    const signIn = (event: React.FormEvent<HTMLFormElement>) => {
        setLoading(true);
        event.preventDefault();
        toggleError(false);
        const data = new FormData(event.currentTarget);
        const user = data.get('username');
        const pass = data.get('password');

        if (!user) {
            toggleError(true);
            setErrorMessage(["Username cannot be empty"]);
            setLoading(false);
            return false;

        }
        if (!pass) {
            toggleError(true);
            setErrorMessage(["Password cannot be empty."]);
            setLoading(false);
            return false;

        }
        if (containsSpecialChars(user)) {
            toggleError(true);
            setErrorMessage(["It appears you entered an email address.", <br/>, "Please use your ContractorWebZone username."]);
            setLoading(false);
            return false;
        }

        // cookies.set('myCat', 'Pacman', {path: '/'});
        // window.location.reload(false);

        api.post(apiUrl() + "api/v2/login/", {
            username: user,
            password: pass,
            rememberMe: rememberMe,
            OS: OS,
            browser: browser,
            mobile: mobile,
            formFactor: formFactor,
            timeZone: timeZone
        }).then(response => {
            console.log(response.data);
            if (response.data.error === true) {
                toggleError(true);
                setErrorMessage([response.data.message]);
                setLoading(false);
                return false;
            }
            if (response.data.status === false) {
                toggleError(true);
                setErrorMessage(["An unexpected error has occurred."]);
                setLoading(false);
                return false;
            }
            if (response.data.status === true) {
                cookies.set('session', response.data.token, {path: '/'});
                cookies.set('count', response.data.cid, {path: '/'});
                cookies.set('contractor', response.data.contractor, {path: '/'});
                cookies.set('adm', response.data.adm, {path: '/'});
                cookies.set('sa', response.data.sa, {path: '/'});
                window.location.reload(false);
            }

        });


    };


    const styles = {
        mainWrapper: {
            backgroundImage: `url(${BG})`,
            height: '100vh',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'none'

        },
        copyright: {
            color: '#FFF'
        }

    };


    const handleCheck = () => {
        setRememberMe(!rememberMe);
        console.log(rememberMe)
    };

    if (isLoading) {
        return (
            <div className="App" style={styles.mainWrapper}>
                <ThemeProvider theme={theme}>
                    <Container component="main" maxWidth="xs">
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            style={{minHeight: '80vh'}}
                        >

                            <Grid item xs={3}>
                                <CircularProgress color="info" size={120}/>
                            </Grid>

                        </Grid>
                    </Container>
                </ThemeProvider>
            </div>
        )
    }


    return (
        <div className="App" style={styles.mainWrapper} >
            <Dialog
                fullWidth
                open={forgotPassOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleForgotPassClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Forgot Your Password?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">

                        <Box sx={{width: "100%"}}>
                            <span style={{color: "#FF0000"}}>&nbsp; {forgotError}</span><br/><br/>
                            {(forgotBody === 'forgot') ?
                                <Box sx={{width: "100%"}}>
                                    <Box>
                                        <Typography>No problem! Let's get started by getting your username.</Typography>
                                    </Box>
                                    <Box sx={{
                                        marginTop: "10px"
                                    }}>
                                        <FormField
                                            style={{width: '100%'}}
                                            label="Username"
                                            placeholder="Username"
                                            onChange={(e) => setForgotUsername(e.target.value)}
                                        />
                                    </Box>
                                </Box> : <></>}
                            {(forgotBody === 'verify') ?
                                <Box sx={{width: "100%"}}>
                                    <Box>
                                        <Typography>OK <span style={{color: "#3061da"}}>{forgotUsername}</span>!<br/>
                                            We're going to send you a code to verify your identity.<br/>
                                            Choose one of the following methods:</Typography>
                                    </Box>
                                    <Box sx={{
                                        marginTop: "10px"
                                    }}>
                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                name="radio-buttons-group"
                                            >
                                                {(forgotPhone) ? <FormControlLabel value="phone" control={<Radio/>} label={forgotPhone} onChange={(e) => handleSetForgotMethod(e.target.value)}/> : <></>}
                                                {(forgotEmail) ? <FormControlLabel value="email" control={<Radio/>} label={forgotEmail} onChange={(e) => handleSetForgotMethod(e.target.value)}/> : <></>}
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                </Box> : <></>}
                            {(forgotBody === 'no_contact') ?
                                <Box sx={{width: "100%"}}>
                                    <Box>
                                        <Typography style={{lineHeight: "30px"}}>
                                            We apologize, <span style={{color: "#3061da"}}>{forgotUsername}</span><br/>
                                            but we don't seem to have any contact information on file for you.<br/>
                                            But don't fret, our customer support is here to help . . .<br/>
                                            Just call our Contractor Care Center at <a href={"tel:+18478847400"}>(847) 884-7400</a> <br/>
                                            Or <a href={"mailto:support@contractorwebzone.com"}>Email Our Support Team</a> <br/>
                                            We'll get you into your account in no time!

                                        </Typography>
                                    </Box>
                                    <Box sx={{
                                        marginTop: "10px"
                                    }}>
                                    </Box>
                                </Box> : <></>}
                            {(forgotBody === 'enter_code') ?
                                <Box sx={{width: "100%"}}>
                                    <Box>
                                        <Typography>Enter the code that was sent to you:</Typography>
                                    </Box>
                                    <Box sx={{
                                        marginTop: "10px"
                                    }}>
                                        <FormField
                                            style={{width: '100%'}}
                                            label="One Time Passcode"
                                            placeholder="One Time Passcode"
                                            onChange={(e) => setCode(e.target.value)}
                                        />
                                    </Box><br/>
                                    <span style={{color: "#2b5cc2"}}>Your One Time Passcode will expire in {timerDisplay}</span>
                                </Box> : <></>}
                            {(forgotBody === 'expired') ?
                                <Box sx={{width: "100%"}}>
                                    <Box>
                                        <Typography style={{color: "#FF0000"}}>
                                            Your session has expired.<br/>
                                            The page will refresh shortly.
                                        </Typography>
                                    </Box>
                                    <Box sx={{
                                        marginTop: "10px"
                                    }}>
                                    </Box><br/>
                                </Box> : <></>}
                            {(forgotBody === 'reset_password') ?
                                <Box sx={{width: "100%"}}>
                                    <Box>
                                        <Typography>Reset Your Password:</Typography>
                                    </Box>
                                    <Box sx={{
                                        marginTop: "10px"
                                    }}>
                                        <FormField
                                            style={{width: '100%'}}
                                            label="Password"
                                            placeholder="Password"
                                            onChange={(e) => setPass(e.target.value)}
                                            type="password"
                                        />
                                        <br/>
                                        <br/>
                                        <FormField
                                            style={{width: '100%'}}
                                            label="Repeat Password"
                                            placeholder="Repeat Password"
                                            onChange={(e) => setRPass(e.target.value)}
                                            type="password"
                                        />
                                    </Box><br/>
                                    <span style={{color: "#2b5cc2"}}>Your reset will expire in {timerDisplay}</span><br/>
                                    <hr/>
                                    <span style={{color: "#777777", fontSize: "18px", fontWeight: "bold"}}>The following must be met</span><br/>
                                    <span style={{color: "#2887a1", fontSize: "12px"}}>AT LEAST 8 characters</span><br/>
                                    <span style={{color: "#2887a1", fontSize: "12px"}}>ONE lowercase letter</span><br/>
                                    <span style={{color: "#2887a1", fontSize: "12px"}}>ONE uppercase letter</span><br/>
                                    <span style={{color: "#2887a1", fontSize: "12px"}}>ONE number [0-9]</span><br/>
                                    <span style={{color: "#2887a1", fontSize: "12px"}}>ONE special character IE [$ @ ! ^ %]</span>
                                </Box> : <></>}


                        </Box>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {(forgotThinking) ? <CircularProgress color="info" size={40}/> :
                        <Box>
                            <Button onClick={handleForgotPassClose} variant="outlined" color="info">Cancel</Button>
                            {(forgotBody === 'forgot') ? <Button onClick={handleForgotPreflight} variant="outlined" color="success">Submit</Button> : <></>}
                            {(forgotBody === 'verify') ? <Button onClick={handleForgotVerify} variant="outlined" color="success">Send Code</Button> : <></>}
                            {(forgotBody === 'enter_code') ? <Button onClick={handleVerifyCode} variant="outlined" color="success">Send Code</Button> : <></>}
                            {(forgotBody === 'reset_password') ? <Button onClick={handleReset} variant="outlined" color="success">Reset Password</Button> : <></>}
                        </Box>
                    }
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth
                open={forgotUserOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleForgotUserClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Forgot Your Username?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">

                        <Box sx={{width: "100%"}}>
                            <span style={{color: "#FF0000"}}>&nbsp; {forgotUError}</span><br/><br/>
                            {(userBody === 'forgot') ?
                                <Box sx={{width: "100%"}}>
                                    <Box>
                                        <Typography sx={{lineHeight: "30px"}}>No problem!<br/>Let's get started by getting your email address.</Typography>
                                    </Box>
                                    <Box sx={{
                                        marginTop: "10px"
                                    }}>
                                        <FormField
                                            style={{width: '100%'}}
                                            label="Email Address"
                                            placeholder="Email Address"
                                            onChange={(e) => setForgotEmail(e.target.value)}
                                        />
                                    </Box>
                                </Box> : <></>}
                            {(userBody === 'done') ?
                                <Box sx={{width: "100%"}}>
                                    <Box>
                                        <Typography sx={{fontSize:"30px;"}}>All set!</Typography>
                                        <Typography sx={{lineHeight: "30px"}}>
                                            An email is on it's way with your username information.</Typography>
                                    </Box>
                                    <Box sx={{
                                        marginTop: "10px"
                                    }}>

                                    </Box>
                                </Box> : <></>}

                        </Box>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {(forgotUThinking) ? <CircularProgress color="info" size={40}/> :
                        <Box>
                            <Button onClick={handleForgotUserClose} variant="outlined" color="info">Cancel</Button>
                            {(userBody === 'forgot') ? <Button onClick={handleForgotUserName} variant="outlined" color="success">Submit</Button> : <></>}

                        </Box>
                    }
                </DialogActions>
            </Dialog>

            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline/>
                    <Box
                        sx={{
                            marginTop: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height: '100%'
                        }}
                    >
                        <Box
                            sx={{
                                marginTop: 19,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Image src="/images/cwz_logo.png" width={"300px"} duration={1000}/>
                            <Box
                                sx={{
                                    marginTop: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    backgroundColor: "rgba(255,255,255,0.8)",
                                    backdropFilter: "saturate(180%) blur(10px)",
                                    borderRadius: "10px",
                                    padding: 4
                                }}
                            >
                                <Typography variant="h4" align="center" color={Colors.ywpYellow.main} fontWeight={'Bold'}>SIGN IN</Typography>
                                <Box component="form" onSubmit={signIn} noValidate sx={{mt: 1}}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="username"
                                        label="Username"
                                        name="username"
                                        autoComplete="username"
                                        autoFocus
                                    />

                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"

                                    />
                                    {displayError &&
                                        <Box sx={{
                                            border: "1px solid #FF0000",
                                            color: "#FF0000",
                                            fontWeight: "bold",
                                            textAlign: "center",
                                            fontSize: "12px"
                                        }}>

                                            {errorMessage}
                                        </Box>
                                    }
                                    <FormControlLabel
                                        control={<Checkbox color="primary"/>}
                                        label="Remember Me"
                                        id={"remember_me"}
                                        name={"remember_me"}
                                        style={styles.loginLabels}
                                        onChange={handleCheck}
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{mt: 3, mb: 2}}
                                    >
                                        Sign In
                                    </Button>
                                    <Grid container>
                                        <Grid item xs>
                                            <Typography style={{
                                                color: "#124676",
                                                textDecoration: "underline", 
                                                fontSize: "14px",
                                                cursor: "pointer"
                                            }}
                                                        onClick={handleForgotPassOpen}
                                            >
                                                Forgot password?
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography style={{
                                                color: "#124676",
                                                textDecoration: "underline",
                                                fontSize: "14px",
                                                cursor: "pointer"
                                            }}
                                                        onClick={handleForgotUserOpen}
                                            >
                                                Forgot username?
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Copyright/>
                </Container>
            </ThemeProvider>
        </div>
    );

}

export default Login;