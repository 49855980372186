/**
 =================================
 ContractorWebZone Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */

import axios from 'axios';
import React, {useEffect, useState} from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import Details from "layouts/myp/components/Details";
import Address from "layouts/myp/components/Address";
import Photos from "layouts/myp/components/Photos";
import ReviewSave from "layouts/myp/components/ReviewSave";
import Finished from "layouts/myp/components/Finished";
import Icon from "@mui/material/Icon";
import colors from "assets/theme/base/colors"
import 'layouts/myp/components/Photos/styles/upload.css';
import {apiUrl, chk} from "App";
import DragSortableList from 'react-drag-sortable'
import api from "utils/axiosConfig";
import Cookies from 'universal-cookie';
import PageLoading from "components/PageLoading";
import DeletePhotoModal from "layouts/myp/components/DeletePhotoModal";

const cookies = new Cookies();

const ywpBlue = colors.ywpBlue.main;
const text = colors.text.main;
const ywpRed = colors.ywpRed.main;
const ywpWhite = colors.ywpWhite.main;
const ywpGreen = colors.ywpGreen.main;


const steps = [
    {
        label: 'Details',
        description: `Basic Details about your project.`,
    }, {
        label: 'Photos',
        description: `Upload photos of your project.`,
    }, {
        label: 'Review and Save',
        description: `Review your project and save to Map`,
    }
];


const PhotosLayout = ({input, previews, submitButton, dropzoneProps, files, extra: {maxFiles}}) => {
    return (
        <div>
            {previews}

            <div {...dropzoneProps} style={{border: "5px dashed #DDD", overflow: "auto", marginTop: "20px"}}>
                {files.length < maxFiles && input}
            </div>

            {files.length > 0 && submitButton}
        </div>
    )
}

const photosPreview = '';


function MYP() {

    const [idInValid, setIdInValid] = useState(false);
    const [isLoading, setLoading] = useState(true);

    {/*Start MYP Form Collection, Storage, and Reset */
    }
    const [isDeviceMobile, setIsMobile] = useState(false);

    function handleWindowSizeChange() {

        if (window.innerWidth <= 440) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }

    useEffect(() => {
        handleWindowSizeChange();
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);


    const [projectName, setProjectName] = useState(null);
    const [clientName, setClientName] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [clientPhone, setClientPhone] = useState('');
    const [description, setDescription] = useState('');
    const [invalidEmail, setInvalidEmail] = React.useState(false);


    const [pageTitle, setPageTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');

    const [filterTags, setFilterTags] = React.useState(["GAF"]);

    const [GAroute, setGAroute] = useState('');
    const [GAstreet, setGAstreet] = useState('');
    const [GAcity, setGAcity] = useState('');
    const [GAstate, setGAstate] = useState('');
    const [GAzip, setGAzip] = useState('');
    const [GAcountry, setGAcountry] = useState('');
    const [GALat, setGALat] = useState('');
    const [GALon, setGALon] = useState('');


    {/*End MYP Form Collection */
    }


    {/*Stepper Wizard */
    }
    const [activeStep, setActiveStep] = React.useState(0);
    const [fileList, setFileList] = React.useState(<></>);
    const [allFileList, setAllFileList] = React.useState([]);

    const [projectNameError, setProjectNameError] = React.useState(false);
    const [clientNameError, setClientNameError] = React.useState(false);
    const [clientEmailError, setClientEmailError] = React.useState(false);
    const [projectDisabled, setProjectDisabled] = React.useState(false);
    const [projectDirectory, setProjectDirectory] = React.useState(false);
    const [saveLoader, setSaveLoader] = React.useState(false);


    const handleNext = () => {
        if (!projectName) {
            setProjectNameError(true);
            return false;
        }
        if (!clientName) {
            setClientNameError(true);
            return false;
        }
        if (!clientEmail || invalidEmail) {
            setClientEmailError(true);
            return false;
        }

        let newName = projectName.toLowerCase();
        newName = newName.trim();

        newName = newName.replace(/ /g, "-");
        newName = newName.replace(/@/g, "");
        newName = newName.replace(/&/g, "-");
        newName = newName.replace(/'/g, "");
        newName = newName.replace(/"/g, "");
        newName = newName.replace(/\./g, "");
        newName = newName.replace(/,/g, "-");
        newName = newName.replace(/:/g, "-");
        newName = newName.replace(/;/g, "-");
        newName = newName.replace(/\+/g, "-");
        newName = newName.replace(/\//g, "");
        newName = newName.replace(/\\/g, "");

        setProjectDirectory(newName)

        setProjectNameError(false);
        setClientNameError(false);
        setClientEmailError(false);
        setProjectDisabled(true);

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    {/*End Stepper Wizard*/
    }


    const getUploadParams = ({file, meta}) => {
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        const url = apiUrl() + 'api/v2/myp/upload/'
        const body = new FormData()
        body.append('fileField', file)
        body.append('cs', cs)
        body.append('session', session)
        body.append('project', projectDirectory)
        return {url: url, body}
    }




    const handlePhotoChangeStatus = ({meta, file, xhr}, status) => {
        // console.log(status, meta)
        if (status === 'done') {
            const file = JSON.parse(xhr.response).file
            let files = allFileList
            console.log(allFileList)
            files.push(file)
            setAllFileList(files)
            document.querySelector(".dzu-previewContainer").remove();

            let dragable_content = [];
            let i = 0;
            while (i < allFileList.length) {
                const temp = {
                    content: (
                        <Box sx={{width: "250px", border: "1px solid #DDD", marginRight: "15px;"}}>
                            <img src={allFileList[i].url} style={{width: "100%", maxHeight: "125px"}}/>
                            <Box sx={{float: "left"}}>{allFileList[i].name}</Box>
                            <Box sx={{float: "right"}}><Icon sx={{color: ywpRed, cursor: "pointer"}}
                                                             onClick={removePhoto}
                                                             data-name={allFileList[i].name}
                                                             data-url={allFileList[i].url}>delete</Icon></Box>
                        </Box>
                    )
                };
                dragable_content.push(temp);
                i++;
            }

            var onSort = function (sortedList, dropEvent) {
                console.log("sortedList", sortedList, dropEvent);
            }

            const drag = (<DragSortableList items={dragable_content} onSort={onSort} type="horizontal"/>);
            setFileList(drag);

        }
    }

    function handleSavePin() {
        setSaveLoader(true)
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        const project_id = cookies.get('myp_e_p_id');
        api.post(apiUrl() + "api/v2/myp/edit_pin/set/", {
            cs: cs,
            session: session,
            project_id: project_id,
            projectName: projectName,
            clientName: clientName,
            clientEmail: clientEmail,
            clientPhone: clientPhone,
            description: description,
            pageTitle: pageTitle,
            metaDescription: metaDescription,
            filterTags: filterTags,
            allFileList: allFileList,
            projectDirectory: projectDirectory,

        }).then(response => {
            // Clear the form
        }).then(response => {
            handleNext();
            setSaveLoader(false);
        });


    }


    useEffect(() => {
        setLoading(true)
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        const project_id = cookies.get('myp_e_p_id');
        if (!project_id) {
            setIdInValid(true);
            return;
        }

        axios.defaults.withCredentials = true;
        api.post(apiUrl() + "api/v2/myp/edit_pin/get/", {
            cs: cs,
            session: session,
            project_id: project_id
        }).then(response => {
            if (response.data.status !== 'ok') {
                setIdInValid(true);
            }
            // alert(response.data.project.name)
            setProjectName(response.data.project.name);
            setClientName(response.data.project.customer_name);
            setClientEmail(response.data.project.customer_email);
            setClientPhone(response.data.project.customer_phone);
            setDescription(response.data.project.job_details);
            //
            setGAroute(response.data.project.address);
            setGAcity(response.data.project.city);
            setGAstate(response.data.project.state);
            setGAzip(response.data.project.zip);
            //
            setPageTitle(response.data.project.title);
            setMetaDescription(response.data.project.meta);
            //

            let filters = [];
            response.data.project.filters.map((item) => {
                filters.push(item)
            });
            setFilterTags(filters);

            let dragable_content = [];
            let i = 0;
            let allFiles = [];
            while (i < response.data.project.images.length) {
                const temp = {
                    content: (
                        <Box sx={{width: "250px", border: "1px solid #DDD", marginRight: "15px;"}}>
                            <img src={response.data.project.images[i].url} style={{width: "100%", maxHeight: "125px"}}/>
                            <Box sx={{float: "left"}}>{response.data.project.images[i].name}</Box>
                            <Box sx={{float: "right"}}><Icon sx={{color: ywpRed, cursor: "pointer"}}
                                                             onClick={removePhoto}
                                                             data-name={response.data.project.images[i].name}
                                                             data-url={response.data.project.images[i].url}>delete</Icon></Box>
                        </Box>
                    )
                };
                dragable_content.push(temp);
                allFiles.push(response.data.project.images[i]);
                i++;
            }
            setAllFileList(allFiles);
            var onSort = function (sortedList, dropEvent) {
                console.log("sortedList", sortedList, dropEvent);
            }

            const drag = (<DragSortableList items={dragable_content} onSort={onSort} type="horizontal"/>);
            setFileList(drag);

            setProjectDisabled(true);

        }).then(response => {
            setLoading(false);
        });
    }, []);

    const [deleteName, setDeleteName] = React.useState(false);
    const [deleteURL, setDeleteURL] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);

    const removePhoto = (e) => {
        const url = e.currentTarget.getAttribute("data-url");
        const name = e.currentTarget.getAttribute("data-name");

        setDeleteName(name);
        setDeleteURL(url);
        setOpenDelete(true)
    }


    if (idInValid) {
        return (
            <Box style={{textAlign: "center"}}>
                There was a problem processing your request. Please try again.
            </Box>
        )
    }
    if (isLoading
        || !projectName
        || !clientName
        || !clientEmail

    ) {
        return (
            <PageLoading/>
        )
    }

    return (

        <DashboardLayout>
            <DashboardNavbar/>

            <Grid
                container
                direction="row"
                justifyContent="space-between"
                spacing={(isDeviceMobile) ? 0 : 3}
                style={{
                    marginLeft: "-10px",
                    minHeight: "600px",
                    width: (isDeviceMobile) ? "97vw" : ''
                }}
            >
                {(!isDeviceMobile) ?
                    <Grid item xs={12} lg={2}>
                        <Box sx={{maxWidth: (isDeviceMobile) ? "100vw" : 400,}}>
                            <Stepper activeStep={activeStep} orientation="vertical">
                                {steps.map((step, index) => (
                                    <Step key={step.label}>
                                        <StepLabel>
                                            {step.label}
                                        </StepLabel>
                                        <StepContent>
                                            <Typography sx={{fontSize: "14px;"}}>{step.description}</Typography>
                                        </StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>
                    </Grid>
                    : <></>
                }
                <Grid item xs={12} lg={10} p={4} sx={{
                    padding: (isDeviceMobile) ? 0 : '',
                    marginTop: (isDeviceMobile) ? "15px" : '',

                }}>
                    <Box>
                        {activeStep === 0 && (
                            <Details
                                handleNext={handleNext}
                                setProjectName={setProjectName}
                                projectName={projectName}
                                setClientName={setClientName}
                                setClientEmail={setClientEmail}
                                setClientPhone={setClientPhone}
                                setDescription={setDescription}
                                clientName={clientName}
                                clientEmail={clientEmail}
                                clientPhone={clientPhone}
                                description={description}
                                pageTitle={pageTitle}
                                setPageTitle={setPageTitle}
                                metaDescription={metaDescription}
                                setMetaDescription={setMetaDescription}
                                filterTags={filterTags}
                                setFilterTags={setFilterTags}
                                isDeviceMobile={isDeviceMobile}
                                projectNameError={projectNameError}
                                clientNameError={clientNameError}
                                clientEmailError={clientEmailError}
                                setInvalidEmail={setInvalidEmail}
                                invalidEmail={invalidEmail}
                                projectDisabled={projectDisabled}
                                fileList={fileList}
                                setClientEmailError={setClientEmailError}
                            />
                        )}
                        {/*{activeStep === 1 && (*/}
                        {/*    <Address*/}
                        {/*        handleNext={handleNext}*/}
                        {/*        handleBack={handleBack}*/}
                        {/*        GAroute={GAroute}*/}
                        {/*        setGAroute={setGAroute}*/}
                        {/*        GAstreet={GAstreet}*/}
                        {/*        setGAstreet={setGAstreet}*/}
                        {/*        GAcity={GAcity}*/}
                        {/*        setGAcity={setGAcity}*/}
                        {/*        GAstate={GAstate}*/}
                        {/*        setGAstate={setGAstate}*/}
                        {/*        GAzip={GAzip}*/}
                        {/*        setGAzip={setGAzip}*/}
                        {/*        GAcountry={GAcountry}*/}
                        {/*        setGAcountry={setGAcountry}*/}
                        {/*        GALat={GALat}*/}
                        {/*        setGALat={setGALat}*/}
                        {/*        GALon={GALon}*/}
                        {/*        setGALon={setGALon}*/}
                        {/*        isDeviceMobile={isDeviceMobile}*/}
                        {/*        fileList={fileList}*/}
                        {/*    />*/}
                        {/*)}*/}
                        {activeStep === 1 && (
                            <Photos
                                handleNext={handleNext}
                                handleBack={handleBack}
                                getUploadParams={getUploadParams}
                                handlePhotoChangeStatus={handlePhotoChangeStatus}
                                PhotosLayout={PhotosLayout}
                                isDeviceMobile={isDeviceMobile}
                                fileList={fileList}
                            />
                        )}
                        {activeStep === 2 && (
                            <ReviewSave
                                handleNext={handleNext}
                                handleBack={handleBack}
                                fileList={fileList}
                                allFileList={allFileList}
                                isDeviceMobile={isDeviceMobile}

                                projectName={projectName}
                                clientName={clientName}
                                clientEmail={clientEmail}
                                clientPhone={clientPhone}
                                description={description}

                                pageTitle={pageTitle}
                                metaDescription={metaDescription}

                                filterTags={filterTags}

                                GAroute={GAroute}
                                GAstreet={GAstreet}
                                GAcity={GAcity}
                                GAstate={GAstate}
                                GAzip={GAzip}
                                GAcountry={GAcountry}
                                GALat={GALat}
                                GALon={GALon}
                                handleSavePin={handleSavePin}
                                saveLoader={saveLoader}
                            />
                        )}
                        {activeStep === steps.length && (
                            <Finished/>
                        )}
                    </Box>
                </Grid>
            </Grid>
            <Footer/>
            <DeletePhotoModal
                openDelete={openDelete}
                setOpenDelete={setOpenDelete}
                deleteName={deleteName}
                deleteURL={deleteURL}
                removePhoto={removePhoto}
                setFileList={setFileList}
                setAllFileList={setAllFileList}

            />
        </DashboardLayout>
    );
}

export default MYP;
