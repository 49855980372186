/**
 =================================
 ContractorWebZone Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */

// @material-ui core components


// Material Dashboard 2 PRO React components

import colors from "assets/theme/base/colors"
import React, {useCallback, useState, useEffect, useMemo} from 'react'

import MDButton from "components/MDButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import CircularProgress from "@mui/material/CircularProgress";
import api from "utils/axiosConfig";
import {apiUrl, chk} from "App";
import Icon from "@mui/material/Icon";
import DragSortableList from "react-drag-sortable";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Delete({openDelete,
                    setOpenDelete,
                    deleteName,
                    deleteURL,
                    setAllFileList,
                    removePhoto,
                    setFileList}) {

    const [deleteLoader, setDeleteLoader] = React.useState(false);

    function handleClose() {
        setOpenDelete(false);
    }

    function handleDeletePhoto() {
        setDeleteLoader(true)
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        api.post(apiUrl() + "api/v2/myp/deletePhoto/", {
            cs: cs,
            session: session,
            deleteName: deleteName,
            deleteURL: deleteURL
        }).then(response => {
            if (response.data.status === 'ok') {

                let i = 0;
                let allFiles = [];
                let dragable_content = [];
                console.log(response.data.images)
                while (i < response.data.images.length) {
                    const temp = {
                        content: (
                            <Box sx={{width: "250px", border: "1px solid #DDD", marginRight: "15px;"}}>
                                <img src={response.data.images[i].url} style={{width: "100%", maxHeight: "125px"}}/>
                                <Box sx={{float: "left"}}>{response.data.images[i].name}</Box>
                                <Box sx={{float: "right"}}><Icon sx={{color: "#FF0000", cursor: "pointer"}}
                                                                 onClick={removePhoto}
                                                                 data-name={response.data.images[i].name}
                                                                 data-url={response.data.images[i].url}>delete</Icon></Box>
                            </Box>
                        )
                    };
                    dragable_content.push(temp);
                    allFiles.push(response.data.images[i]);
                    i++;
                }
                setAllFileList(allFiles);
                var onSort = function (sortedList, dropEvent) {
                    console.log("sortedList", sortedList, dropEvent);
                }

                const drag = (<DragSortableList items={dragable_content} onSort={onSort} type="horizontal"/>);
                setFileList(drag);
            }
            setDeleteLoader(false);
            setOpenDelete(false)
        });
    }

    return (
        <Dialog
            open={openDelete}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{"Are you sure you want to delete this photo?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    This will permanently remove the photo from MapYourProjects.
                    This action cannot be undone.
                </DialogContentText>
            </DialogContent>
            {(deleteLoader) ?

                <Box sx={{textAlign: "right"}}>
                    <CircularProgress color="info" size={40} style={{marginRight: "20px"}}/>
                </Box>
                :
                <DialogActions>
                    <MDButton onClick={handleClose} variant="outlined" color="info">Cancel</MDButton>
                    <MDButton id="deletePinSubmit"
                              onClick={handleDeletePhoto}
                              variant="outlined"
                              color="error">Delete Photo</MDButton>
                </DialogActions>
            }

        </Dialog>
    );
}

export default Delete;
