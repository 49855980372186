// @mui material components
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Rankings from "layouts/seo/components/rankings";
import PageViews from "layouts/dashboard/components/WebsiteMetrics/components/WebsiteTraffic/components/PageViews";
import Engagement from "layouts/dashboard/components/WebsiteMetrics/components/WebsiteTraffic/components/Engagement";
import ByLocale from "layouts/dashboard/components/WebsiteMetrics/components/WebsiteTraffic/components/ByLocale";
import SessionsByState
    from "layouts/dashboard/components/WebsiteMetrics/components/WebsiteTraffic/components/SessionsByState";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IframeResizer from 'iframe-resizer-react'
import CircularProgress from "@mui/material/CircularProgress";
import { ComposableMap, Geographies, Geography, Marker, Annotation } from "react-simple-maps";
import { scaleQuantile } from "d3-scale";
import { geoCentroid } from "d3-geo";
import { csv } from "d3-fetch";
import Card from "assets/theme/components/card";
import CardContent from "assets/theme/components/card/cardContent";
// const geoUrl = "/gadm.json";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Report({ report }) {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <Box>
            <Grid container sx={{ marginTop: "25px;" }} spacing={5} justifyContent="center">
                <Grid item lg={5} md={12}>
                    <ByLocale />
                </Grid>
                <Grid item lg={7} md={12}>
                    <SessionsByState />
                </Grid>
                <Grid item lg={6} md={12}>
                    <Engagement />
                </Grid>
                <Grid item lg={6} md={12}>
                    <PageViews />
                </Grid>
            </Grid>
        </Box>
    )
}

export default Report;