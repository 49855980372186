import {useState, useEffect, useMemo} from "react";
import {Routes, Route, Navigate, useLocation} from "react-router-dom";
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "components/Sidenav";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import routes from "routes";
import {useMaterialUIController, setMiniSidenav, setOpenConfigurator} from "context";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export function chk() {
    const session = cookies.get('session');
    const cs = cookies.get('count');
    const adm = cookies.get('adm');
    return {"session": session, "cs": cs, "adm": adm};
}


export function apiUrl() {
    return 'https://api.yourwebpro.com/';
}

/* App default session code(s) */


export default function App() {


    const [controller, dispatch] = useMaterialUIController();
    const {
        miniSidenav,
        direction,
        layout,
        openConfigurator,
        darkMode,
    } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const {pathname} = useLocation();


    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    // Change the openConfigurator state
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute("dir", direction);
    }, [direction]);

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route) {
                return <Route exact path={route.route} element={route.component} key={route.key}/>;
            }

            return null;
        });


    return (
        <ThemeProvider theme={darkMode ? themeDark : theme}>
            <CssBaseline/>
            {layout === "dashboard" && (
                <>
                    <Sidenav
                        brandName="ContractorWebZone"
                        routes={routes}
                        onMouseEnter={handleOnMouseEnter}
                        onMouseLeave={handleOnMouseLeave}
                    />
                </>
            )}

            <Routes>
                {getRoutes(routes)}
                <Route path="*" element={<Navigate to="/dashboard"/>}/>
            </Routes>

        </ThemeProvider>
    );
}
