import React, { useEffect, useState } from 'react';
import api from 'utils/axiosConfig';
import { apiUrl, chk } from "App.js";
import Box from "@mui/material/Box";
import Select from 'react-select';
import { DataGrid } from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Typography from "@mui/material/Typography";
import colors from 'assets/theme/base/colors';


export default function Engagement() {

    const durationSelect = [
        { value: '6daysAgo', label: '7 Days' },
        { value: '13daysAgo', label: '2 Weeks' },
        { value: '29daysAgo', label: '1 Month' },
        { value: '59daysAgo', label: '2 Months' },
        { value: '89daysAgo', label: '3 Months' },
        { value: '179daysAgo', label: '6 Months' },
        { value: '364daysAgo', label: '1 Year' },
    ];


    // const [labels, setLables] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // const [data, setData] = useState([]);
    const [rows, setRows] = useState([]);
    const [submissions, setSubmissions] = useState(0);


    function handleDurationChange(e) {
        setIsLoading(true);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/analyticsV2/sessionByCity/", { cs: cs, session: session, duration: e.value }).then(response => {
            setRows(response.data.metrics);
        }).then(response => {
            setIsLoading(false);
        });

        api.post(apiUrl() + "api/v2/analyticsV2/trafficByChannel/", { cs: cs, session: session, duration: e.value }).then(response => {
            setSubmissions(response.data.submissions)
        }).then(response => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        handleDurationChange({ value: "26daysAgo" })
    }, []);


    const columns = [
        {
            field: 'id',
            headerName: ' ',
            maxWidth: 50,
            minWidth: 15,
        },

        {
            field: 'city',
            headerName: 'City',
            maxWidth: 150,
            minWidth: 100,
            renderCell: (params) => (
                <span style={{ cursor: "pointer", fontWeight: "bold", color: "#4157de" }}>{params.value}</span>
            )
        },

        {
            field: 'session',
            headerName: 'Sessions',
            type: 'number',
            align: 'center',
            maxWidth: 90,
            minWidth: 60,
        },

        {
            field: 'user',
            headerName: 'New Visitors',
            type: 'number',
            align: 'center',
            maxWidth: 130,
            minWidth: 120,
        }
    ];

    return (
        <>
            {(isLoading) ?
                <Box sx={{ textAlign: "center" }}>
                    <CircularProgress color="info" size={120} />
                </Box>
                :
                <MDBox sx={{ borderRadius: "10px", backgroundColor:colors.ywpYellow.main }}>
                    <MDBox p={1} mx={3} display="flex" alignItems="center" justifyContent="center">
                        <MDTypography sx={{ color: colors.ywpWhite.main, fontWeight: "bolder" }} fontSize="2rem">
                            {submissions}
                        </MDTypography>
                    </MDBox>
                    <MDBox pb={2} mb={3} textAlign="center">
                        <MDTypography color="white" variant="h6" fontWeight="medium" textTransform="capitalize">
                            Form Submissions Last 30 Days
                        </MDTypography>
                    </MDBox>
                </MDBox>
            }
            <Box style={{ backgroundColor: "white", borderRadius: "10px", padding: "5%", boxShadow: 1 }}>
            
                <Typography sx={{ fontWeight: "bold" }}>
                    Local Area Traffic
                </Typography>
                <Select
                    isSearchable="false"
                    placeholder={"Duration"}
                    defaultValue={"29daysAgo"}
                    onChange={e => handleDurationChange(e)}
                    options={durationSelect}
                />
                {(isLoading) ?
                    <Box sx={{ textAlign: "center" }}>
                        <CircularProgress color="info" size={120} />
                    </Box>
                    :
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[10]}
                        // onCellClick={handleOnCellClick}
                        disableRowSelectionOnClick
                    // checkboxSelection
                    />
                }
            </Box>
        </>
    );
}
